/*
@File: Novine Template Styles

* This file contains the styling for the actual tepmlate, this
is the file you need to edit to change the look of the
tepmlate.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Top Panel Area CSS
** - Top Header Area CSS
** - Navbar Area CSS
** - Modal CSS
** - Main Banner Area CSS
** - About Area CSS
** - Offer Area CSS
** - All Products Area CSS
** - Products QuickView Modal Area CSS
** - Category Products Area CSS
** - Trending Products Area CSS
** - Best Sellers Area CSS~
** - Facility Area CSS
** - Testimonials Area CSS
** - Products Offer Area CSS
** - News Area CSS
** - News Details Area CSS
** - Subscribe Area CSS
** - Partner Area CSS
** - Instagram Area CSS
** - Page Title Area CSS
** - Products Collections Area CSS
** - Woocommerce Sidebar Area CSS
** - Products Filter Modal Area CSS
** - Gallery Area CSS
** - Look Book Area CSS
** - Sizing Guide Area CSS
** - 404 Error Area CSS
** - FAQs Area CSS
** - Contact Area CSS
** - Products Details Area CSS
** - Pagination Area CSS
** - Sidebar Area CSS
** - Cart Area CSS
** - Checkout Area CSS
** - Compare Area CSS
** - Login Area CSS
** - Signup Area CSS
** - Customer Service Area CSS
** - Footer Area CSS
** - Go Top CSS
*/
/*================================================
Default CSS
=================================================*/
@import url('https://fonts.googleapis.com/css?family=Archivo:400,400i,500,500i,600,600i,700,700i&display=swap');

$font-family: 'Archivo', sans-serif;
$font-size: 14px;
$paragraph-color: #666666;
$black-color: #222222;
$white-color: #ffffff;
$transition: .5s;

:root {
    scroll-behavior: inherit;
}

body {
    padding: 0;
    margin: 0;
    font: {
        family: $font-family;
        size: 14px;
    }
}
p {
    color: $paragraph-color;
    line-height: 1.8;
    font-size: $font-size;
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }
}
a {
    display: block;
    transition: $transition;
    text-decoration: none;

    &:hover, &:focus {
        text-decoration: none;
    }
}
.d-table {
    width: 100%;
    height: 100%;

    &-cell {
        vertical-align: middle;
    }
}
.ptb-60 {
    padding: {
        top: 60px;
        bottom: 60px;
    }
}
.pb-60 {
    padding-bottom: 60px;
}
.pt-60 {
    padding-top: 60px;
}
.pb-30 {
    padding-bottom: 30px;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    &:last-child {
        margin-bottom: 0;
    }
}
img {
    max-width: 100%;
}
.form-check-input {
    margin-top: 3px;
}
/*section-title*/
.section-title {
    margin-bottom: 40px;
    text-align: center;
    background-color: #fcfbfb;
    padding: 15px;

    h2 {
        margin-bottom: 0;
        display: inline-block;
        font-size: 20px;
        position: relative;
        padding-left: 20px;
        font-weight: 600;

        .dot {
            position: absolute;
            top: 4px;
            left: 0;
            width: 12px;
            height: 12px;
            border: 1px solid $black-color;

            &::before {
                position: absolute;
                top: 0;
                content: '';
                left: 0;
                right: 0;
                bottom: 0;
                background: $black-color;
                margin: 1px;
            }
        }
    }
    &.without-bg {
        padding: 0;
        background-color: transparent;
    }
}
/*btn(primary&light)*/
.btn {
    border: 1px solid transparent;
    padding: 11px 25px;
    line-height: initial;
    border-radius: 0;
    transition: $transition;
    text-transform: uppercase;
    font: {
        weight: 400;
        size: $font-size;
    }
    &.disabled, &:disabled {
        opacity: 1;
    }
}
.btn-primary {
    color: $white-color;
    background-color: $black-color;
    border-color: $black-color;

    &.disabled, &:disabled {
        color: $white-color;
        background-color: $black-color;
        border-color: $black-color;
    }
    &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {
        box-shadow: unset;
        background-color: transparent;
        color: $black-color;
        border-color: $black-color;
    }
    &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus, .show>&.dropdown-toggle:focus {
        box-shadow: unset;
    }
    &:hover, &:focus {
        box-shadow: unset;
        background-color: transparent;
        color: $black-color;
        border-color: $black-color;
    }
}
.btn-light {
    background-color: transparent;
    color: $black-color;
    border-color: $black-color;

    &.disabled, &:disabled {
        background-color: transparent;
        color: $black-color;
        border-color: $black-color;
    }
    &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {
        box-shadow: unset;
        color: $white-color;
        background-color: $black-color;
        border-color: $black-color;
    }
    &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus, .show>&.dropdown-toggle:focus {
        box-shadow: unset;
    }
    &:hover, &:focus {
        box-shadow: unset;
        color: $white-color;
        background-color: $black-color;
        border-color: $black-color;
    }
}
/*owl-carousel-btn*/
.owl-theme {
    .owl-nav {
        &.disabled+.owl-dots {
            margin-top: 20px;
            line-height: initial;
        }
    }
    .owl-dots {
        .owl-dot {
            outline: 0;

            span {
                margin: 0 3px !important;
                transition: $transition !important;
                border-radius: 50% !important;
                width: 10px !important;
                height: 10px !important;
                background: #d2d2d2 !important;
            }
            &:hover, &.active {
                span {
                    background: $black-color !important;
                }
            }
        }
    }
}
/*form-control*/
.form-control {
    background-color: #f5f5f5;
    width: 100%;
    height: 45px;
    padding: 0 15px;
    font-size: 14px;
    line-height: initial;
    color: $black-color;
    border: none;
    border-radius: 0;
    transition: $transition;

    &:focus {
        outline: 0;
        background-color: #f5f5f5;
        box-shadow: unset;
    }
    &::placeholder {
        color: #999999;
    }
}

/*================================================
Top Panel Area CSS
=================================================*/
.top-panel {
    background-color: $black-color;
    text-align: center;
    position: relative;
    transition: $transition;
    height: auto;
    padding: {
        top: 13px;
        bottom: 13px;
    }
    .panel-content {
        transition: $transition;

        p {
            margin-bottom: 0;
            line-height: initial;
            color: $white-color;
            font: {
                size: 17px;
                style: italic;
            }
            b {
                font-size: 20px;
            }
            a {
                display: inline-block;
                line-height: initial;
                color: $white-color;
                position: relative;
                font: {
                    weight: 700;
                    style: normal;
                }
                &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    width: 0;
                    height: 2px;
                    left: 0;
                    transition: $transition;
                    background: $white-color;
                }
                &:hover, &:focus {
                    &::before {
                        width: 100%;
                    }
                }
            }
        }
    }
    .container {
        position: relative;
    }
    .panel-close-btn {
        position: absolute;
        right: 15px;
        top: 50%;
        z-index: 2;
        transform: translateY(-50%);
        color: $white-color;
        border-radius: 50%;
        cursor: pointer;
        transition: $transition;
        opacity: 0.9;

        &:hover, &:focus {
            color: $white-color;
            opacity: 1;
        }
    }
    &.hide {
        opacity: 0;
        visibility: hidden;
        height: 0;
        transition: $transition;
        padding: 0;

        .panel-content {
            height: 0;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
        }
    }
}

/*================================================
Top Header Area CSS
=================================================*/
.top-header {
    background-color: #f1f1f1;
    padding: {
        top: 10px;
        bottom: 10px;
    }
    .container-fluid {
        padding: {
            left: 35px;
            right: 35px;
        };
    }
}
.top-header-nav {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;

    li {
        display: inline-block;
        position: relative;
        margin-right: 17px;
        line-height: initial;

        a {
            color: $black-color;
            font-size: 13px;
            line-height: initial;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 1px;
                background: $black-color;
                transition: $transition;
            }
            &:hover, &:focus {
                color: $black-color;

                &::before {
                    width: 100%;
                }
            }
        }
        &::before {
            content: '';
            position: absolute;
            right: -9px;
            top: 0.5px;
            width: 1px;
            height: 12px;
            background: #888888;
        }
        &:last-child {
            margin-right: 0;

            &::before {
                display: none;
            }
        }
    }
}
.ui-autocomplete {
    color: red !important;
    -moz-border-radius: 15px;
    border-radius: 15px;
}
.top-header-right-nav {
    text-align: right;
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;

    li {
        display: inline-block;
        position: relative;
        margin-right: 17px;
        line-height: initial;
        
        a {
            color: $black-color;
            font-size: 13px;
            line-height: initial;
            position: relative;

            i {
                margin-left: 2px;
            }
            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 1px;
                background: $black-color;
                transition: $transition;
            }
            &:hover, &:focus {
                color: $black-color;

                &::before {
                    width: 100%;
                }
            }
        }
        &::before {
            content: '';
            position: absolute;
            right: -9px;
            top: 0.5px;
            width: 1px;
            height: 12px;
            background: #888888;
        }
        &:last-child {
            margin-right: 0;

            &::before {
                display: none;
            }
        }
    }
    .languages-list {
        select {
            background: transparent;
            outline: 0;
            padding: 0;
            border: none;
            cursor: pointer;
        }
    }
}

/*================================================
Navbar Area CSS
=================================================*/
.navbar-area {
    .is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        background-color: $white-color !important;
        -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    }
}
.navbar-brand {
    position: relative;
    top: -1px;
    padding: {
        top: 0;
        bottom: 0;
    }
}
.comero-nav {
    background-color: $white-color;
    padding: {
        top: 3px;
        bottom: 3px;
    }
    .navbar {
        position: inherit;
        padding: {
            right: 0;
            top: 0;
            left: 0;
            bottom: 0;
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;
        }
        .navbar-toggler {
            box-shadow: none;
        }
        .navbar-nav {
            margin-left: auto;
            margin-right: auto;

            .nav-item {
                padding: {
                    top: 20px;
                    bottom: 20px;
                    left: 0;
                    right: 0;
                }
                &.p-relative {
                    position: relative;
                }
                a {
                    font: {
                        size: 14px;
                    }
                    color: $black-color;
                    text-transform: uppercase;
                    padding: {
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                    }
                    margin: {
                        left: 10px;
                        right: 10px;
                    }
                    &:hover, &:focus, &.active {
                        color: $black-color;
                    }
                    i {
                        font-size: 11px;
                    }
                }
                &:hover, &:focus, &.active {
                    a {
                        color: $black-color;
                    }
                }
                .dropdown-menu {
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                    background: $white-color;
                    position: absolute;
                    border: none;
                    top: 80px;
                    left: 0;
                    width: 250px;
                    z-index: 99;
                    display: block;
                    opacity: 0;
                    visibility: hidden;
                    border-radius: 0;
                    transition: all 0.3s ease-in-out;
                    padding: {
                        top: 20px;
                        left: 5px;
                        right: 5px;
                        bottom: 20px;
                    }
                    li {
                        position: relative;
                        padding: 0;

                        a {
                            text-transform: capitalize;
                            padding: 8px 15px;
                            margin: 0;
                            display: block;
                            color: $paragraph-color;
                            font: {
                                size: 14px;
                            }
                            &:hover, &:focus, &.active {
                                color: $black-color;
                            }

                            position: relative;

                            .new {
                                background-color: #dc3545;
                                color: #fff;
                                font-size: 8px;
                                padding: 2px 5px;
                                border-radius: 4px;
                                position: relative;
                                top: -8px;
                                left: 7px;
                            }
                        }
                        .dropdown-menu {
                            left: 220px;
                            top: 0;
                            opacity: 0;
                            visibility: hidden;

                            li {
                                a {
                                    color: $paragraph-color;
                                    text-transform: capitalize;

                                    &:hover, &:focus, &.active {
                                        color: $black-color;
                                    }
                                }
                            }
                            li {
                                position: relative;
                                padding: 0;
        
                                a {
                                    text-transform: capitalize;
                                    padding: 8px 15px;
                                    margin: 0;
                                    display: block;
                                    color: $paragraph-color;
                                    font: {
                                        size: 14px;
                                    }
                                    &:hover, &:focus, &.active {
                                        color: $black-color;
                                    }
                                }
                                .dropdown-menu {
                                    left: auto;
                                    right: 240px;
                                    top: 0;
                                    opacity: 0;
                                    visibility: hidden;
        
                                    li {
                                        a {
                                            color: $black-color;
                                            text-transform: capitalize;
        
                                            &:hover, &:focus, &.active {
                                                color: $black-color;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    a {
                                        color: $black-color;
                                    }
                                }
                                &:hover {
                                    .dropdown-menu {
                                        opacity: 1;
                                        visibility: visible;
                                        top: -15px;
                                    }
                                }
                            }
                        }
                        &.active {
                            a {
                                color: $black-color;
                            }
                        }
                        &:hover {
                            .dropdown-menu {
                                opacity: 1;
                                visibility: visible;
                                top: -15px;
                            }
                        }
                    }
                }
                &:hover {
                    .dropdown-menu {
                        opacity: 1;
                        visibility: visible;
                        top: 100%;
                    }
                }
                &:last-child {
                    .dropdown-menu {
                        left: auto;
                        right: 0;
                    }
                }
                &.megamenu {
                    .dropdown-menu {
                        padding: 35px 0;
                        position: absolute;
                        top: auto;
                        left: 0;
                        margin-top: 23px;
                        width: 100%;

                        .submenu-title {
                            color: $black-color;
                            font-size: 15px;
                            text-transform: uppercase;
                            margin-bottom: 20px;
                        }
                        .megamenu-submenu {
                            li {
                                margin-top: 12px;

                                &:first-child {
                                    margin-top: 0;
                                }
                                a {
                                    padding: 0;
                                }
                            }
                            &.top-brands {
                                margin: {
                                    left: -5px;
                                    top: -5px;
                                    right: -5px;
                                    bottom: -5px;
                                }
                                li {
                                    display: inline-block;
                                    position: relative;
                                    z-index: 1;
                                    padding: {
                                        left: 5px;
                                        top: 5px;
                                        bottom: 5px;
                                        right: 5px;
                                    }
                                    &::before {
                                        content: '';
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        width: 100%;
                                        height: 100%;
                                        z-index: -1;
                                        border: 1px solid #eeeeee;
                                        transition: $transition;
                                    }
                                    &:hover {
                                        &::before {
                                            border-color: $black-color;
                                        }
                                    }
                                }
                            }
                            .aside-trending-products {
                                overflow: hidden;
                                position: relative;
                                text-align: center;
                                                     
                                .category {
                                    position: absolute;
                                    left: 0;
                                    transition: $transition;
                                    right: 0;
                                    bottom: 0;
                                    background-color: $white-color;
                                    padding: 20px;
                                    margin: {
                                        left: 10px;
                                        right: 10px;
                                    }
                                    h4 {
                                        font-size: 15px;
                                        text-transform: uppercase;
                                        margin-bottom: 0;
                                        position: relative;
                                        z-index: 1;
                                    }
                                }
                                img {
                                    transition: $transition;
                                    width: 100%;
                                }
                                a {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    z-index: 3;
                                }
                                &:hover, &:focus {
                                    img {
                                        transform: scale(1.3);
                                    }
                                    .category {
                                        bottom: 10px;
                                    }
                                }
                                &:not(:first-child) {
                                    margin-top: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .others-option {
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 0;

        .option-item {
            display: inline-block;
            position: relative;
            margin-right: 17px;
            line-height: initial;
    
            a {
                color: $black-color;
                text-transform: uppercase;
                position: relative;
    
                &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0;
                    height: 1px;
                    background: $black-color;
                    transition: $transition;
                }
                &:hover, &:focus {
                    color: $black-color;
    
                    &::before {
                        width: 100%;
                    }
                }
            }
            &::before {
                content: '';
                position: absolute;
                right: -9px;
                top: 0.5px;
                width: 1px;
                height: 12px;
                background: #888888;
            }
            &:last-child {
                margin-right: 0;
    
                &::before {
                    display: none;
                }
            }
            .search-btn {
                cursor: pointer;
            }
            .close-btn {
                cursor: pointer;
                display: none;

                &.active {
                    display: block;
                }
            }
        }
    }
}

// Navbar toggler 
.navbar-toggler {
    border: none;
    background: #eee !important;
    padding: 10px;
	border-radius: 0;
	
	&:focus {
		box-shadow: none;
	}

    .icon-bar {
        width: 28px;
        transition: all 0.3s;
        background: #333;
        height: 2px;
        display: block;
    }
    .top-bar {
        transform: rotate(45deg);
        transform-origin: 10% 10%;
        left: 4px;
        position: relative;
    }
    .middle-bar {
        opacity: 0;
        margin: 6px 0;
    }
    .bottom-bar {
        transform: rotate(-45deg);
        transform-origin: 10% 90%;
        left: 4px;
        position: relative;
    }
    &.collapsed {
        .top-bar {
            transform: rotate(0);      
            left: 0;
        }
        .middle-bar {
            opacity: 1;
        }
        .bottom-bar {
            transform: rotate(0); 
            left: 0;
        }
    }
}

/*================================================
Modal CSS
=================================================*/
.shoppingCartModal {
    &.modal.right {
        .modal-dialog {
            position: fixed;
            margin: auto;
            width: 320px;
            height: 100%;
            transform: translate3d(0%, 0, 0);
        }
        .modal-content {
            height: 100%;
            overflow-y: auto;
        }
        &.fade {
            .modal-dialog {
                right: -420px;
                transition: opacity 0.3s linear, right 0.3s ease-out;
            }
            &.show {
                .modal-dialog {
                    right: 0;
                }
            }
        }
    }
    .modal-content {
        border-radius: 0;
        border: none;

        .modal-body {
            padding: 25px 15px;

            h3 {
                font-size: 17px;
                text-transform: uppercase;
                margin-bottom: 25px;
            }
            .product-cart-content {
                color: $paragraph-color;

                .product-cart {
                    position: relative;
                    border-bottom: 1px dashed #eeeeee;
                    margin-bottom: 15px;
                    padding: {
                        left: 60px;
                        bottom: 15px;
                    }
                    .product-image {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 50px;
                    }
                    .product-content {
                        h3 {
                            font-size: 13px;
                            text-transform: initial;
                            margin-bottom: 5px;

                            a {
                                color: $black-color;
                            }
                        }
                        span {
                            font-size: 12px;
                            display: block;
                            margin-bottom: 3px;
                            color: $paragraph-color;
                        }
                        .product-price {
                            span {
                                display: inline-block;
                                margin-bottom: 0;
                                font-size: 12px;
                                color: $paragraph-color;

                                &.price {
                                    font-size: 14px;
                                    color: $black-color;
                                }
                                &:nth-child(2) {
                                    margin: {
                                        left: 2px;
                                        right: 2px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .product-cart-subtotal {
                overflow: hidden;
                margin-bottom: 15px;
                margin-top: 15px;

                span {
                    text-transform: uppercase;
                    display: inline-block;
                    font-size: 15px;

                    &.subtotal {
                        float: right;
                    }
                }
            }
            .product-cart-btn {
                .btn {
                    display: block;

                    &.btn-light {
                        margin-top: 12px;
                    }
                }
            }
        }
    }
    .close {
        color: $black-color;
        font-size: 30px;
        outline: 0 !important;
        line-height: initial;
        transition: $transition;
        position: absolute;
        right: 15px;
        top: 15px;
        background: transparent;
        border: none;
        z-index: 1;

        &:not(:disabled):not(.disabled){
            &:hover, &:focus{
                color: $black-color;
                text-decoration: none;
                opacity: 1;
            }
        }
    }
}
.search-overlay {
    display: none;

    &.search-popup {
        position: absolute;
        top: 100%;
        width: 300px;
        right: 0;
        background: $white-color;
        z-index: 2;
        padding: 20px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        margin-top: 26px;

        .search-form {
            position: relative;
            
            .search-input {
                display: block;
                width: 100%;
                height: 40px;
                line-height: initial;
                border: 1px solid #eeeeee;
                padding-left: 10px;
                color: $black-color;
                outline: 0;
            }
            .search-button {
                position: absolute;
                right: 0;
                top: 0;
                height: 40px;
                background: transparent;
                border: none;
                width: 40px;
                outline: 0;
                color: $paragraph-color;
                transition: $transition;

                &:hover, &:focus {
                    color: $black-color;
                }
            }
        }
    }
}
.bts-popup {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 9991;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s 0s, visibility 0s 0.3s;

    &.is-visible {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s 0s, visibility 0s 0s;

        .bts-popup-container {
            transform: translateY(-50%);
        }
    }
    .bts-popup-container {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        z-index: 1;
        max-width: 600px;
        margin: 0 auto;
        background: $white-color;
        border-radius: none; 
        text-align: center;
        transform: translateY(-40px);
        transition-property: transform;
        transition-duration: 0.3s;
        padding: 50px 130px;
        overflow: hidden;

        .bts-popup-button {
            padding: 5px 25px;
            border: 2px solid $black-color;
            display: inline-block;
            margin-bottom: 10px;
        }
        a {
            color: $black-color;
            text-decoration: none;
            text-transform: uppercase;
        }
        .bts-popup-close {
            position: absolute;
            top: 8px;
            right: 8px;
            width: 30px;
            height: 30px;

            &::before {
                transform: rotate(45deg);
                left: 8px;
            }
            &::after {
                transform: rotate(-45deg);
                right: 6px;
                top: 13px;
            }
            &::before, &::after {
                content: '';
                position: absolute;
                top: 12px;
                width: 16px;
                height: 3px;
                background-color: $black-color;
            }
        }
        h3 {
            text-transform: uppercase;
            margin-bottom: 15px;
            font: {
                size: 24px;
                weight: 700;
            }
            p {
                margin-bottom: 25px;
                font-size: 13px;
            }
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            margin: {
                bottom: 0;
                top: 20px;
            }
            li {
                display: inline-block;
                margin-right: 5px;

                a {
                    width: 33px;
                    height: 33px;
                    background-color: $black-color;
                    color: $white-color;
                    line-height: 35px;
                    border-radius: 50%;

                    &.facebook {
                        background-color: #3b5998;
                        color: $white-color;
                    }
                    &.twitter {
                        background-color: #1da1f2;
                        color: $white-color;
                    }
                    &.linkedin {
                        background-color: #0077b5;
                        color: $white-color;
                    }
                    &.instagram {
                        background-color: #e1306c;
                        color: $white-color;
                    }
                }
            }
        }
        .img-box {
            img {
                &:nth-child(1) {
                    position: absolute;
                    left: -45px;
                    bottom: 0;
                    z-index: -1;
                    width: 185px;
                }
                &:nth-child(2) {
                    position: absolute;
                    right: -45px;
                    bottom: 0;
                    z-index: -1;
                    width: 185px;
                }
            }
        }
        form {
            position: relative;
        
            button {
                position: absolute;
                right: 0;
                top: 0;
                height: 45px;
                border: none;
                background-color: $black-color;
                color: $white-color;
                outline: 0;
                font-size: 16px;
                cursor: pointer;
                transition: $transition;
                padding: 0 20px;
        
                &:hover, &:focus {
                    background-color: #000000;
                }
            }
            .validation-danger {
                color: red;
                margin-top: 10px;
            }
            .validation-success {
                margin-top: 10px;
            }
        }
    }
}
.modal {
    background-color: #0000004d;
}
.shoppingWishlistModal {
    &.modal.right {
        .modal-dialog {
            position: fixed;
            margin: auto;
            width: 320px;
            height: 100%;
            transform: translate3d(0%, 0, 0);
        }
        .modal-content {
            height: 100%;
            overflow-y: auto;
        }
        &.fade {
            .modal-dialog {
                right: -420px;
                transition: opacity 0.3s linear, right 0.3s ease-out;
            }
            &.show {
                .modal-dialog {
                    right: 0;
                }
            }
        }
    }
    .modal-content {
        border-radius: 0;
        border: none;

        .modal-body {
            padding: 25px 15px;

            h3 {
                font-size: 17px;
                text-transform: uppercase;
                margin-bottom: 25px;
            }
            .product-cart-content {
                .product-cart {
                    position: relative;
                    border-bottom: 1px dashed #eeeeee;
                    margin-bottom: 15px;
                    padding: {
                        left: 60px;
                        bottom: 15px;
                    }
                    .product-image {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 50px;
                    }
                    .product-content {
                        h3 {
                            font-size: 13px;
                            text-transform: initial;
                            margin-bottom: 5px;

                            a {
                                color: $black-color;
                            }
                        }
                        span {
                            font-size: 12px;
                            display: block;
                            margin-bottom: 3px;
                            color: $paragraph-color;
                        }
                        .product-price {
                            span {
                                display: inline-block;
                                margin-bottom: 0;
                                font-size: 12px;
                                color: $paragraph-color;

                                &.price {
                                    font-size: 14px;
                                    color: $black-color;
                                }
                            }
                        }
                    }
                }
            }
            .product-cart-btn {
                .btn {
                    display: block;

                    &.btn-light {
                        margin-top: 15px;
                    }
                }
            }
        }
    }
    .close {
        color: $black-color;
        font-size: 30px;
        outline: 0 !important;
        line-height: initial;
        transition: $transition;
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 1;
        border: none;
        background-color: transparent;
        opacity: .7;

        &:not(:disabled):not(.disabled){
            &:hover, &:focus{
                color: $black-color;
                text-decoration: none;
                opacity: 1;
            }
        }
    }
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
    height: 600px;
    position: relative;
    z-index: 1;
    background: {
        color: #f1f1f1;
        size: cover;
        repeat: no-repeat;
        position: center center;
    }
    &.item-bg1 {
        background-image: url(/images/main-banner1.jpg);
    }
    &.item-bg2 {
        background-image: url(/images/main-banner2.jpg);
    }
    &.item-bg3 {
        background-image: url(/images/main-banner3.jpg);
    }
    &.item-bg4 {
        background-image: url(/images/main-banner4.jpg);
    }
}
.main-banner-content {
    max-width: 520px;

    span {
        font-size: $font-size;
        color: $paragraph-color;
        display: block;
    }
    h1 {
        font: {
            size: 34px;
            weight: 700;
        }
        text-transform: uppercase;
        margin: {
            top: 8px;
            bottom: 15px;
        }
    }
    p {
        line-height: initial;
        font-size: 18px;
        color: $black-color;
        margin-bottom: 35px;
    }
    .btn-primary {
        margin-right: 15px;
    }
    &.white-color {
        span {
            color: $white-color;
        }
        h1 {
            color: $white-color;
        }
        p {
            color: $white-color;
        }
        .btn-primary {
            &:hover, &:focus {
                background-color: $white-color;
                color: $black-color;
                border-color: $white-color;
            }
        }
    }
}
.home-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;

            [class*=owl-] {
                position: absolute;
                left: 160px;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
                outline: 0;
                width: 45px;
                height: 45px;
                line-height: 39px;
                transition: $transition;
                background-color: #959595;
                color: $white-color;
                border-radius: 50%;
                opacity: 1;
                visibility: hidden;

                &:hover, &:focus {
                    background-color: $black-color;
                }
                &.owl-next {
                    left: auto;
                    right: 160px;
                }
            }
        }
        &:hover {
            .owl-nav {
                [class*=owl-] {
                    opacity: 1;
                    visibility: visible;
                    left: 140px;
                    transition: $transition;
    
                    &.owl-next {
                        left: auto;
                        right: 140px;
                    }
                }
            }
        }
        .owl-dots {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 20px;
        }
    }
}
.main-banner-two {
    padding-top: 30px;
    position: relative;
    z-index: 1;
    background-color: #f1f1f1;
}
.main-banner-three {
    height: 820px;
}
.banner-image {
    text-align: center;
    position: relative;
    z-index: 1;

    img {
        width: unset !important;
        display: inline-block !important;
    }
    .circle {
        width: 470px;
        height: 470px;
        border-radius: 50%;
        background-color: $white-color;
        z-index: -1;
        position: absolute;
        left: 0;
        top: 5%;
        right: 0;
        margin: 0 auto;
    }
}

/*================================================
About Area CSS
=================================================*/
.about-area {
    position: relative;
    z-index: 1;
}
.about-content {
    h2 {
        font-size: 24px;
        margin-bottom: 15px;
    }
    .signature {
        margin: {
            top: 35px;
        }
    }
}
.about-image {
    position: relative;
    padding-bottom: 160px;

    .about-img1 {
        position: relative;
        right: -100px;
    }
    .about-img2 {
        position: absolute;
        left: 20px;
        bottom: 0;
    }
}
@keyframes movebounce {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}

/*================================================
Offer Area CSS
=================================================*/
.offer-area {
    .section-title {
        background-color: transparent;
        padding: 0;

        h2 {
            padding-left: 0;
            font-weight: 700;

            .dot {
                display: none;
            }
        }
    }
}
.offer-box {
    overflow: hidden;
    position: relative;
    text-align: center;

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 100%;
        background-color: $white-color;
        opacity: 0.4;
        transition: $transition;
        z-index: 2;
    }
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: -100%;
        background-color: $white-color;
        opacity: 0.4;
        transition: $transition;
        z-index: 2;
    }
    .category {
        position: absolute;
        left: 0;
        transition: $transition;
        right: 0;
        bottom: 25px;
        border: 1px solid #ececec;
        padding: 20px;
        margin: {
            left: 40px;
            right: 40px;
        }
        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background: #ededed;
            opacity: 0.94;
            z-index: 1;
            margin: 6px;
        }
        h4 {
            font-size: 16px;
            text-transform: uppercase;
            margin-bottom: 0;
            position: relative;
            z-index: 1;
        }
    }
    img {
        transition: $transition;
    }
    .box-inner {
        position: absolute;
        top: 50px;
        left: 0;
        padding: 60px 15px;
        right: 0;
        bottom: 0;
        border: 1px solid #ededed;
        margin: 30px;
        z-index: 2;
        opacity: 0;
        visibility: hidden;
        transition: $transition;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background: #ededed;
            opacity: 0.94;
            z-index: 1;
            margin: 6px;
            z-index: -1;
        }
        h3 {
            font-size: 17px;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    font-size: 13px;
                    color: $paragraph-color;
                    position: relative;
                    display: inline-block;

                    &::before {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 0;
                        height: 1px;
                        background: $black-color;
                        transition: $transition;
                    }
                    &:hover, &:focus {
                        color: $black-color;

                        &::before {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    &:hover, &:focus {
        img {
            transform: scale(1.3);
        }
        &::after {
            left: 100%;
        }
        &::before {
            left: -100%;
        }
        .category {
            opacity: 0;
            visibility: hidden;
            bottom: 0;
        }
        .box-inner {
            opacity: 1;
            visibility: visible;
            top: 0;
        }
    }
}
.single-offer-box {
    position: relative;
    overflow: hidden;
    text-align: center;
    border-radius: 2px;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $black-color;
        opacity: .50;
        z-index: 2;
        border-radius: 2px;
    }
    img {
        transition: $transition;
        border-radius: 2px;
    }
    .offer-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 3;

        h3 {
            color: $white-color;
            font: {
                size: 19px;
                weight: 700;
            }
            margin-bottom: 6px;
        }
        span {
            display: block;
            color: $white-color;
        }
    }
    a {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
    }
    &:hover {
        img {
            transform: scale(1.3);
        }
    }
}
.offer-slides {
    &.owl-theme {
        .owl-dots {
            margin-top: 30px;
        }
        .owl-nav {
            margin-top: 0;

            [class*=owl-] {
                position: absolute;
                left: -5px;
                top: 0;
                height: 83.4%;
                width: 34px;
                background-color: #dedddc;
                font-size: 20px;
                transition: $transition;
                outline: 0;
                border-radius: 0;
                margin: 0;
                opacity: 0;
                visibility: hidden;
                color: $black-color;

                &:hover {
                    background-color: $black-color;
                    color: $white-color;
                }
                &.owl-next {
                    left: auto;
                    right: -5px;
                }
                i {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    text-align: center;
                }
            }
        }
        &:hover {
            .owl-nav {
                [class*=owl-] {
                    left: -18px;
                    opacity: 1;
                    visibility: visible;

                    &.owl-next {
                        left: auto;
                        right: -18px;
                    }
                }
            }
        }
    }
}

/*================================================
All Products Area CSS
=================================================*/
.all-products-area {
    padding-bottom: 30px;
}
.tab {
    .tabs_item {
        display: none;
        
        &:first-child {
            display: block;
        }
    }
    .tabs_item_best {
        display: none;
        
        &:first-child {
            display: block;
        }
    }
    .tabs_item_trending {
        display: none;
        
        &:first-child {
            display: block;
        } 
    }
    .tabs_item_popular {
        display: none;
        
        &:first-child {
            display: block;
        }
    }
    .tabs_item_special {
        display: none;
        
        &:first-child {
            display: block;
        }
    }
}
.products-category-tab {
    .tabs {
        list-style-type: none;
        margin-bottom: 40px;
        text-align: center;
        background-color: #fcfbfb;
        padding: 15px;

        li {
            display: inline-block;
            margin: 0 10px;

            .tabs-nav-text, a {
                display: inline-block;
                font-size: 18px;
                position: relative;
                padding-left: 18px;
                color: #858585;
                cursor: pointer;
        
                .dot {
                    position: absolute;
                    top: 7px;
                    left: 0;
                    width: 12px;
                    height: 12px;
                    border: 1px solid #858585;
                    transition: $transition;
        
                    &::before {
                        position: absolute;
                        top: 0;
                        content: '';
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: #858585;
                        margin: 1px;
                        transition: $transition;
                    }
                }
                &:hover, &:focus {
                    color: $black-color;

                    .dot {
                        border-color: $black-color;

                        &::before {
                            background: $black-color;
                        }
                    }
                }
            }
            &.current {
                .tabs-nav-text, a {
                    color: $black-color;

                    .dot {
                        border-color: $black-color;

                        &::before {
                            background: $black-color;
                        }
                    }
                }
            }
        }
        &.without-bg {
            padding: 0;
            background-color: transparent;
        }
    }
}
.single-product-box {
    margin-bottom: 30px;
    position: relative;
    text-align: center;

    .product-image {
        position: relative;

        img {
            width: 100%;
            
            &:nth-child(2) {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
                transition: $transition;
                opacity: 0;
                visibility: hidden;
            }
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            position: absolute;
            top: 10px;
            right: 0;
            opacity: 0;
            visibility: hidden;
            transition: $transition;

            li {
                margin-bottom: 8px;

                a {
                    width: 40px;
                    height: 40px;
                    line-height: 42px;
                    background-color: $white-color;
                    color: $black-color;
                    border-radius: 50%;
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

                    &:hover, &:focus {
                        color: $white-color;
                        background-color: $black-color;
                    }
                    &.disabled {
                        cursor: not-allowed;
                        background-color: $white-color !important;
                        color: $black-color !important;
                        background: green !important;
                        color: #fff !important;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .sale-tag {
            position: absolute;
            top: 10px;
            left: 10px;
            background: #ff2d2d;
            color: $white-color;
            width: 50px;
            height: 50px;
            line-height: 50px;
            border-radius: 50%;
            z-index: 2;
        }
        #timer {
            position: absolute;
            left: 0;
            text-align: center;
            right: 0;
            margin: 0 auto;
            bottom: 15px;

            div {
                display: inline-block;
                background-color: rgba(255, 255, 255, 0.9);
                padding: 8px 15px;
                color: $black-color;
                border-radius: 5px;
                font: {
                    size: 18px;
                    weight: 600;
                }
                span {
                    display: block;
                    font: {
                        size: 13px;
                        weight: normal;
                    }
                    color: $paragraph-color;
                }
            }
        }
    }
    .product-content {
        margin-top: 20px;

        h3 {
            font-size: 16px;
            margin-bottom: 0;

            a {
                color: $black-color;

                &:hover, &:focus {
                    color: $black-color;
                }
            }
        }
        .product-price {
            margin: {
                top: 10px;
                bottom: 10px;
            }
            span {
                font-size: 18px;
                display: inline-block;
            }
            .old-price {
                margin-right: 5px;
                color: #858585;
                font-size: 15px;
                text-decoration: line-through;
            }
        }
        .rating {
            margin-bottom: 12px;
            color: #eac11d;
            i {
                margin: 0 2px;
            }
        }
        .btn {
            display: block;
            width: 100%;
        }
    }
    &:hover, &:focus {
        .product-image {
            img {
                &:nth-child(2) {
                    opacity: 1;
                    visibility: visible;
                }
            }
            ul {
                right: 10px;
                opacity: 1;
                visibility: visible;
            }
        }
        .product-content {
            .btn-light {
                background-color: $black-color;
                color: $white-color;
            }
        }
    }
}
.all-products-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;

            [class*=owl-] {
                position: absolute;
                left: -5px;
                top: 0;
                height: 62.5%;
                width: 34px;
                background-color: #dedddc;
                font-size: 20px;
                transition: $transition;
                outline: 0;
                border-radius: 0;
                margin: 0;
                opacity: 0;
                visibility: hidden;
                color: $black-color;

                &:hover {
                    background-color: $black-color;
                    color: $white-color;
                }
                &.owl-next {
                    left: auto;
                    right: -5px;
                }
                i {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    text-align: center;
                }
            }
        }
        &:hover {
            .owl-nav {
                [class*=owl-] {
                    left: -18px;
                    opacity: 1;
                    visibility: visible;

                    &.owl-next {
                        left: auto;
                        right: -18px;
                    }
                }
            }
        }
    }
}

/*================================================
Products QuickView Modal Area CSS
=================================================*/
.productQuickView {
    .modal-dialog {
        max-width: 900px;
        margin: 0 auto;
    }
    .modal-content {
        border: none;
        padding: 40px;
        border-radius: 0;

        button {
            &.close {
                background-color: transparent;
                position: absolute;
                right: 0;
                top: 0;
                outline: 0;
                opacity: 1;
                color: $black-color;
                transition: $transition;
                line-height: 35px;
                padding: 0;
                margin: 0;
                font-size: 20px;
                width: 35px;
                text-shadow: unset;
                height: 35px;
                border: none;

                &:hover {
                    background-color: red;
                    color: $white-color;
                }
            }
        }
        .productQuickView-image {
            img {
                width: 100%;
            }
        }
        .product-content {
            h3 {
                font-size: 22px;
                margin-bottom: 8px;

                a {
                    color: $black-color;

                    &:hover {
                        color: $black-color;
                    }
                }
            }
            .price {
                margin-bottom: 5px;
                
                span {
                    display: inline-block;
                    font-size: 18px;
                    color: $black-color;
                }
            }
            .product-review {
                .rating {
                    display: inline-block;
                    padding-right: 10px;

                    i {
                        color: #ffba0a;
                        margin: 0 2px;
                    }
                }
                .rating-count {
                    display: inline-block;
                    color: $black-color;
                    border-bottom: 1px solid $black-color;
                    line-height: initial;
                }
            }
            .product-info {
                list-style-type: none;
                padding: 0;
                margin: {
                    top: 20px;
                    bottom: 0;
                }
                li {
                    font-size: 16px;
                    color: $black-color;
                    margin-bottom: 8px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    span {
                        color: $paragraph-color;
                    }
                    a {
                        display: inline-block;
                        font-weight: 500;
                        color: $black-color;

                        &:hover {
                            color: $black-color;
                        }
                    }
                }
            }
            .product-color-switch {
                margin-top: 8px;

                h4 {
                    font-size: 16px;
                    color: $paragraph-color;
                    margin-bottom: 6px;
                }
                ul {
                    list-style-type: none;
                    margin: {
                        bottom: 0;
                        top: -4px;
                        left: -4px;
                    }
                    padding: {
                        left: 0;
                    }
                    li {
                        display: inline-block;
                        margin: {
                            left: 4px;
                            top: 4px;
                        }
                        a {
                            display: inline-block;
                            position: relative;
                            border: 1px solid transparent;
                            width: 25px;
                            height: 25px;
                            border-radius: 50%;
        
                            &::before {
                                position: absolute;
                                content: '';
                                left: 0;
                                right: 0;
                                top: 0;
                                bottom: 0;
                                background: #eeeeee;
                                border-radius: 50%;
                                margin: 2px;
                                transition: $transition;
                            }
                            &:hover, &:focus {
                                border-color: $black-color;
                            }
                            &.color-white {
                                &::before {
                                    background: $white-color;
                                    border: 1px solid #eeeeee;
                                }
                            }
                            &.color-black {
                                &::before {
                                    background: $black-color;
                                }
                            }
                            &.color-red {
                                &::before {
                                    background: red;
                                }
                            }
                            &.color-blue {
                                &::before {
                                    background: blue;
                                }
                            }
                            &.color-green {
                                &::before {
                                    background: green;
                                }
                            }
                            &.color-yellow {
                                &::before {
                                    background: yellow;
                                }
                            }
                            &.color-yellowgreen {
                                &::before {
                                    background: yellowgreen;
                                }
                            }
                            &.color-pink {
                                &::before {
                                    background: pink;
                                }
                            }
                            &.color-violet {
                                &::before {
                                    background: violet;
                                }
                            }
                            &.color-teal {
                                &::before {
                                    background: teal;
                                }
                            }
                            &.color-plum {
                                &::before {
                                    background: plum;
                                }
                            }
                            &.color-lime {
                                &::before {
                                    background: lime;
                                }
                            }
                            &.color-blueviolet {
                                &::before {
                                    background: blueviolet;
                                }
                            }
                        }
                        &.active {
                            a {
                                border-color: $black-color;
                            }
                        }
                    }
                }
            }
            .product-size-wrapper {
                margin: {
                    top: 8px;
                    bottom: 25px;
                }
                h4 {
                    font-size: 16px;
                    color: $paragraph-color;
                    margin-bottom: 8px;
                }
                ul {
                    list-style-type: none;
                    margin: {
                        bottom: 0;
                        top: -8px;
                        left: -8px;
                    }
                    padding: {
                        left: 0;
                    }
                    li {
                        display: inline-block;
                        margin: {
                            left: 8px;
                            top: 8px;
                        }
                        a {
                            width: 40px;
                            height: 35px;
                            display: inline-block;
                            line-height: 35px;
                            border: 1px solid #eeeeee;
                            color: #858585;
                            text-align: center;

                            &:hover, &:focus {
                                border-color: $black-color;
                                color: $black-color;
                            }
                        }
                        &.active {
                            a {
                                border-color: $black-color;
                                color: $black-color;
                            }
                        }
                    }
                }
            }
            .product-add-to-cart {
                .input-counter {
                    max-width: 130px;
                    min-width: 130px;
                    margin-right: 10px;
                    text-align: center;
                    display: inline-block;
                    position: relative;    

                    span {
                        position: absolute;
                        top: 0;
                        background-color: transparent;
                        cursor: pointer;
                        color: #d0d0d0;
                        width: 40px;
                        height: 100%;
                        line-height: 50px;
                        transition: $transition;

                        &.minus-btn {
                            left: 0;
                        }
                        &.plus-btn {
                            right: 0;
                        }
                        &:hover {
                            color: $black-color;
                        }
                    }
                    input {
                        height: 45px;
                        color: $black-color;
                        outline: 0;
                        display: block;
                        border: none;
                        background-color: #f8f8f8;
                        text-align: center;
                        width: 100%;
                        font: {
                            size: 17px;
                            weight: 600;
                        }
                        &::placeholder {
                            color: $black-color;
                        }
                    }
                }
                .btn {
                    height: 45px;
                    top: -2px;
                    position: relative;

                    i {
                        margin-right: 2px;
                    }
                }
            }
            .view-full-info {
                margin-top: 25px;
                color: $black-color;
                line-height: initial;
                display: inline-block;
                position: relative;
                font: {
                    weight: 600;
                    size: 15px;
                }
                &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0;
                    height: 1px;
                    background: $black-color;
                    transition: $transition;
                }
                &:hover {
                    &::before {
                        width: 100%;
                    }
                }
            }
        }
    }
}

/*================================================
Category Products Area CSS
=================================================*/
.category-products-area {
    padding-bottom: 30px;

    &.pt-60 {
        padding-bottom: 0;
        margin-bottom: -30px;
    }
}
.single-category-box {
    margin-bottom: 30px;
    position: relative;
    text-align: center;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $black-color;
        transition: $transition;
        opacity: .7;
        z-index: 2;
    }
    img {
        transition: $transition;
        width: 100%;
    }
    .category-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 3;

        h3 {
            margin-bottom: 0;
            color: $white-color;
            font: {
                size: 20px;
                weight: 700;
            }
        }
        .btn {
            margin-top: 22px;
        }
        .btn-light {
            background-color: transparent;
            color: $white-color;
            border-color: $white-color;
        
            &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {
                color: $black-color;
                background-color: $white-color;
                border-color: $white-color;
            }
            &:hover, &:focus {
                color: $black-color;
                background-color: $white-color;
                border-color: $white-color;
            }
        }
    }
    .link-btn {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
    }
    &:hover, &:focus {
        img {
            transform: scale(1.1);
        }
    }
}

/*================================================
Trending Products Area CSS
=================================================*/
.trending-products-area {
    padding-bottom: 30px;
}
.trending-products-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;

            [class*=owl-] {
                position: absolute;
                left: -5px;
                top: 0;
                height: 62.5%;
                width: 34px;
                background-color: #dedddc;
                font-size: 20px;
                transition: $transition;
                outline: 0;
                border-radius: 0;
                margin: 0;
                opacity: 0;
                visibility: hidden;
                color: $black-color;

                &:hover {
                    background-color: $black-color;
                    color: $white-color;
                }
                &.owl-next {
                    left: auto;
                    right: -5px;
                }
                i {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    text-align: center;
                }
            }
        }
        &:hover {
            .owl-nav {
                [class*=owl-] {
                    left: -18px;
                    opacity: 1;
                    visibility: visible;

                    &.owl-next {
                        left: auto;
                        right: -18px;
                    }
                }
            }
        }
    }
}
.trending-products-slides-two {
    &.owl-theme {
        .owl-nav {
            &.disabled + .owl-dots {
                line-height: initial;
                margin: {
                    bottom: 30px;
                    top: 10px;
                }
            }
        }
    }
}

/*================================================
Best Sellers Area CSS
=================================================*/
.best-sellers-area {
    padding-bottom: 30px;
}
.best-sellers-products-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;

            [class*=owl-] {
                position: absolute;
                left: -5px;
                top: 0;
                height: 62.5%;
                width: 34px;
                background-color: #dedddc;
                font-size: 20px;
                transition: $transition;
                outline: 0;
                border-radius: 0;
                margin: 0;
                opacity: 0;
                visibility: hidden;
                color: $black-color;

                &:hover {
                    background-color: $black-color;
                    color: $white-color;
                }
                &.owl-next {
                    left: auto;
                    right: -5px;
                }
                i {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    text-align: center;
                }
            }
        }
        &:hover {
            .owl-nav {
                [class*=owl-] {
                    left: -18px;
                    opacity: 1;
                    visibility: visible;

                    &.owl-next {
                        left: auto;
                        right: -18px;
                    }
                }
            }
        }
    }
}
.best-sellers-products-slides-two {
    &.owl-theme {
        .owl-nav {
            &.disabled + .owl-dots {
                line-height: initial;
                margin: {
                    bottom: 30px;
                    top: 10px;
                }
            }
        }
    }
}

/*================================================
Facility Area CSS
=================================================*/
.facility-area {
    background-color: #f5f5f5;
    padding: {
        top: 35px;
        bottom: 35px;
    }
    &.black-bg {
        background-color: $black-color;

        .facility-box {
            h3 {
                color: $white-color;
            }
        }
    }
}
.facility-box {
    text-align: center;

    .icon {
        display: inline-block;
        width: 80px;
        height: 80px;
        font-size: 25px;
        color: $black-color;
        line-height: 80px;
        border: 1px solid $white-color;
        border-radius: 50%;
        position: relative;
        z-index: 1;

        &::before {
            z-index: -1;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: $white-color;
            margin: 6px;
            border-radius: 50%;
        }
        i {
            transition: $transition;
        }
    }
    h3 {
        margin: {
            bottom: 0;
            top: 15px;
        }
        text-transform: uppercase;
        font-size: 15px;
    }
    &:hover, &:focus {
        .icon {
            i {
                transform: rotateY(-180deg);
            }
        }
    }
}

/*================================================
Testimonials Area CSS
=================================================*/
.testimonials-area {
    position: relative;
    z-index: 1;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
        image: url(/images/testimonials-bg.jpg);
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: $black-color;
        opacity: .70;
    }
    &.bg2 {
        background-image: url(/images/testimonials-bg2.jpg);
        background-attachment: fixed;
    }
}
.single-testimonials {
    text-align: center;
    margin: 0 auto;
    max-width: 745px;

    .client-image {
        margin-bottom: 30px;

        img {
            width: 65px !important;
            border-radius: 50%;
            height: 65px;
            display: inline-block !important;
        }
    }
    p {
        color: $white-color;
        margin-bottom: 0;
        font-style: italic;
        line-height: 1.9;
    }
    .client-info {
        margin-top: 25px;

        h4 {
            font-size: 18px;
            color: $white-color;
        }
        span {
            display: block;
            color: $white-color;
            font-size: 13px;
            opacity: .95;
        }
    }
}
.testimonials-slides {
    &.owl-theme {
        .owl-nav {
            &.disabled+.owl-dots {
                margin-top: 30px;
            }
        }
        .owl-dots {
            .owl-dot {
                outline: 0;
    
                span {
                    background: transparent !important;
                    border: 1px solid $white-color;
                }
                &:hover, &.active {
                    span {
                        background: $white-color !important;
                    }
                }
            }
        }
    }
}

/*================================================
Products Offer Area CSS
=================================================*/
.products-offer-area {
    position: relative;
    z-index: 1;
    background: {
        image: url(/images/offer-bg.jpg);
        position: center center;
        size: cover;
        attachment: fixed;
        repeat: no-repeat;
    }
    &.bg-image2 {
        background: {
            image: url(/images/offer-bg2.jpg);
        }
    }
}
.products-offer-content {
    max-width: 550px;
    border: 1px solid $white-color;
    text-align: center;
    padding: 70px 40px;
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: $white-color;
        z-index: -1;
        margin: 10px;
    }
    span {
        font-size: 16px;
        display: block;
    }
    h1 {
        font: {
            size: 55px;
            weight: 700;
        }
        margin: {
            top: 0;
            bottom: 5px;
        }
    }
    p {
        line-height: initial;
        margin-bottom: 25px;
        font-size: 18px;
        color: $black-color;
    }
}

/*================================================
News Area CSS
=================================================*/
.single-news-post {
    margin-bottom: 30px;
    .news-content {
        position: relative;
        background: rgba(245, 245, 245, .95);
        z-index: 1;
        padding: 30px;
        h3 {
            margin-bottom: 0;
            line-height: 1.4;
            font: {
                size: 22px;
                weight: 700;
            }
            a {
                color: $black-color;

                &:hover, &:focus {
                    color: $black-color;
                }
            }
        }
        .author {
            display: block;
            color: $paragraph-color;
            margin: {
                top: 12px;
                bottom: 15px;
            }
            a {
                display: inline-block;
                color: $black-color;

                &:hover, &:focus {
                    color: $black-color;
                }
            }
        }
        p {
            margin-bottom: 20px;
        }
    }
}
.single-blog-post {
    margin-bottom: 30px;
    background-color: $white-color;
    box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.06);

    .blog-image {
        position: relative;
        overflow: hidden;

        img {
            width: 100%;
        }
     
        a {
            display: block;

            img {
                transition: $transition;
            }
        }
        .post-tag {
            position: absolute;
            left: 0;
            top: 40px;
            transition: $transition;

            a {
                background: $black-color;
                color: $white-color;
                padding: 7px 20px;
            }
        }
    }
    .blog-post-content {
        padding: 25px;

        .date {
            color: $paragraph-color;
            display: block;
            text-transform: uppercase;
            transition: $transition;
            font: {
                size: 13px;
            }
        }
        h3 {
            margin: {
                top: 10px;
                bottom: 12px;
            }
            line-height: 27px;
            font: {
                size: 18px;
                weight: 600;
            }
            a {
                color: $black-color;

                &:hover, &:focus {
                    color: $black-color;
                }
            }
        }
        p {
            margin-bottom: 15px;
        }
    }
    .read-more-btn {
        position: relative;
        font-weight: 500;
        color: $paragraph-color;

        &:hover {
            color: $black-color;
            letter-spacing: 1px;
        }
    }
    &:hover, &:focus {
        .blog-image {
            a {
                img {
                    transform: scale(1.2);
                }
            }
            .post-tag {
                top: 50px;
    
                a {
                    background: $black-color;
                    color: $white-color;
                }
            }
        }
    }
}

/*================================================
News Details Area CSS
=================================================*/
.blog-details {
    .article-img {
        img {
            width: 100%;
        }
    }
    .article-header {
        border: 1px solid #eeeeee;
        border-bottom: none;
        padding: 30px;

        .entry-meta {
            padding: 0;
            margin: 0 0 17px;
            list-style-type: none;

            li {
                display: inline-block;
                color: #777777;
                margin-right: 15px;
                font: {
                    size: 14px;
                }
                i {
                    color: $black-color;
                    margin-right: 3px;
                }
                &:last-child {
                    margin-right: 0;
                }
                a {
                    display: inline-block;
                    color: $paragraph-color;

                    &:hover {
                        color: $black-color;
                    }
                }
            }
        }
        h3 {
            margin-bottom: 0;
            font: {
                size: 19px;
            }
        }
    }
    .article-content {
        padding: 30px 30px 35px;
        border: 1px solid #eeeeee;
        border-top: none;

        .entry-meta {
            padding: 0;
            margin: 0 0 17px;
            list-style-type: none;

            li {
                display: inline-block;
                color: #777777;
                margin-right: 15px;
                font: {
                    size: 14px;
                }
                i {
                    color: $black-color;
                    margin-right: 3px;
                }
                &:last-child {
                    margin-right: 0;
                }
                a {
                    display: inline-block;
                    color: $paragraph-color;

                    &:hover {
                        color: $black-color;
                    }
                }
            }
        }
        .category {
            padding: 0;
            margin: 30px 0 0;
            list-style-type: none;

            li {
                display: inline-block;
                margin-right: 4px;

                span {
                    font-weight: 600;
                }
                a {
                    border: 1px solid $black-color;
                    border-radius: 5px;
                    padding: 4px 15px;
                    font-size: 13px;
                    color: $black-color;
                    position: relative;
                    z-index: 1;

                    &::before {
                        position: absolute;
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background: $black-color;
                        z-index: -1;
                        border-radius: 5px;
                        opacity: 0;
                        visibility: hidden;
                        transition: $transition;
                    }
                    &:hover, &:focus {
                        color: $white-color;

                        &::before {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
        h3 {
            margin-bottom: 17px;
            font: {
                size: 19px;
            }
        }
    }
}
blockquote {
    background-color: #fafafa;
    padding: 30px 30px 30px 72px !important;
    line-height: 25px;
    font-weight: 500;
    position: relative;
    margin: 20px 0;

    &::before {
        font-family: "Font Awesome 5 Free";
        content: "\f10d";
        position: absolute;
        left: 30px;
        top: 35px;
        color: $black-color;
        font: {
            size: 25px;
            weight: 900;
            style: normal;
        }
    }
    p {
        font-weight: 600;
    }
    cite {
        position: relative;
        padding-left: 17px;
        font: {
            weight: 700;
        }
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background: $black-color;
            left: 0;
            width: 10px;
            height: 2px;
        }
    }
}
.post-controls-buttons {
    overflow: hidden;
    padding: 30px;
    border: 1px solid #eeeeee;
    margin: {
        top: 30px;
        bottom: 30px;
    }
    div {
        &:first-child {
            float: left;
        }
        &:last-child {
            float: right;
        }
        a {
            display: inline-block;
            border: 1px solid #eeeeee;
            padding: 11px 20px 9px;
            color: $black-color;
            text-transform: uppercase;
            border-radius: 30px;
            font: {
                weight: 600;
                size: 13px;
            }
            &:hover, &:focus {
                border-color: $black-color;
                color: $white-color;
                background-color: $black-color;
            }
        }
    }
}
.comments-area {
    .comments-title {
        font: {
            size: 20px;
        }
        margin: {
            bottom: 30px;
            top: 30px;
        }
    }
    ol, ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .comment-list {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .children {
        margin-left: 25px;
    }
    .comment-body {
        border-bottom: 1px dashed #eeeeee;
        margin-left: 65px;
        color: $black-color;
        font-size: 14px;
        margin-bottom: 20px;
        padding-bottom: 20px;

        .reply {
            margin-top: 15px;

            a {
                border: 1px solid $black-color;
                color: $black-color;
                display: inline-block;
                padding: 5px 20px;
                border-radius: 30px;
                text-transform: uppercase;
                font: {
                    size: 12px;
                    weight: 600;
                }
                &:hover {
                    color: $white-color;
                    background-color: $black-color;
                }
            }
        }
    }
    .comment-meta {
        margin-bottom: .8em;
    }
    .comment-author {
        font-size: 15px;
        margin-bottom: 0.4em;
        position: relative;
        z-index: 2;

        .avatar {
            height: 50px;
            left: -65px;
            position: absolute;
            width: 50px;
        }
        .fn {
            font: {
                weight: 600;
            }
        }
        .says {
            display: none;
        }
    }
    .comment-metadata {
        color: $paragraph-color;
        letter-spacing: 0.10em;
        text-transform: uppercase;
        font: {
            size: 11px;
            weight: 600;
        }
        a {
            color: $paragraph-color;

            &:hover {
                color: $black-color;
            }
        }
    }
    .comment-content {
        p {
            font-size: 14px;
        }
    }
    .comment-respond {
        background: #fafafa;
        padding: 30px 25px;
        overflow: hidden;
        border: 1px solid #eeeeee;

        .comment-reply-title {
            margin: {
                top: 0;
                bottom: 0;
            }
            font: {
                size: 20px;
            }
            #cancel-comment-reply-link {
                font-size: 15px;
                display: inline-block;
            }
        }
        .comment-notes {
            font-size: 15px;
            margin: {
                bottom: 0;
                top: 10px;
            }
            .required {
                color: red;
            }
        }
        .comment-form-comment {
            margin-top: 15px;
            float: left;
            width: 100%;
        }
        label {
            display: block;
            margin-bottom: 10px;
            color: $paragraph-color;
            text-transform: uppercase;
            margin-bottom: 5px;
            font: {
                size: 13px;
                weight: 600;
            }
        }
        input[type="date"], input[type="time"], input[type="datetime-local"], input[type="week"], input[type="month"], input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="tel"], input[type="number"], textarea {
            display: block;
            width: 100%;
            background-color: $white-color;
            border: 1px solid #eeeeee;
            padding: 0.625em 0.7375em;
            outline: 0;
            transition: $transition;

            &:focus {
                border-color: $black-color;
            }
        }
        .comment-form-author {
            float: left;
            width: 50%;
            padding-right: 10px;
            margin-bottom: 20px;
        }
        .comment-form-email {
            float: left;
            width: 50%;
            padding-left: 12px;
            margin-bottom: 20px;
        }
        .comment-form-url {
            float: left;
            width: 100%;
            margin-bottom: 20px;
        }
        .comment-form-cookies-consent {
            width: 100%;
            float: left;
            position: relative;
            padding-left: 20px;
            margin-bottom: 20px;

            input {
                position: absolute;
                left: 0;
                top: 7px;
            }
            label {
                display: inline-block;
                margin: 0;
                color: $paragraph-color;
                font-weight: normal;
                text-transform: initial;
            }
        }
        .form-submit {
            float: left;
            width: 100%;

            input {
                background: $black-color;
                border: none;
                color: $white-color;
                padding: 10.5px 25px;
                display: inline-block;
                cursor: pointer;
                outline: 0;
                border-radius: 0;
                text-transform: uppercase;
                transition: $transition;
                font: {
                    weight: 600;
                    size: 14px;
                }
                &:hover, &:focus {
                    background-color: $black-color;
                    color: $white-color;
                }
            }
        }
    }
}

/*================================================
Subscribe Area CSS
=================================================*/
.subscribe-area {
    border-top: 1px solid #f5f5f5;
}
.newsletter-content {
    h3 {
        font-size: 20px;
        text-transform: uppercase;
        margin-bottom: 7px;
    }
}
.newsletter-form {
    position: relative;

    button {
        position: absolute;
        right: 0;
        top: 0;
        height: 45px;
        border: none;
        background-color: $black-color;
        color: $white-color;
        outline: 0;
        font-size: 16px;
        cursor: pointer;
        transition: $transition;
        padding: 0 20px;

        &:hover, &:focus {
            background-color: #000000;
        }
    }
    .validation-danger {
        color: red;
        margin-top: 10px;
    }
    .validation-success {
        margin-top: 10px;
    }
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
    background-color: #f5f5f5;
    padding: {
        top: 30px;
        bottom: 30px;
    }
}
.partner-item {
    text-align: center;

    a {
        img {
            width: auto !important;
            display: inline-block !important;
        }
    }
}

/*================================================
Instagram Area CSS
=================================================*/
.instagram-box {
    text-align: center;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: #000000;
        left: 0;
        transition: $transition;
        visibility: hidden;
        opacity: 0;
        z-index: 1;
    }
    .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        font-size: 30px;
        margin-top: 20px;
        color: $white-color;
        transition: $transition;
        visibility: hidden;
        opacity: 0;
        display: inline-block;
        z-index: 2;
    }
    a {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 3;
    }
    &:hover, &:focus {
        &::before {
            visibility: visible;
            opacity: .65;
        }
        .icon {
            opacity: 1;
            visibility: visible;
            margin-top: 0;
        }
    }
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
    background-color: #f8f8f8;
    padding: {
        top: 12px;
        bottom: 12px;
    }
    ul {
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 0;

        li {
            display: inline-block;
            margin-right: 15px;
            position: relative;
            color: $paragraph-color;
            font-size: 13px;

            a {
                color: $paragraph-color;
                font-size: 13px;

                &:hover, &:focus {
                    color: $black-color;
                }
            }
            &::before {
                content: '/';
                position: absolute;
                display: inline-block;
                left: -10px;
                top: 0;
            }
            &:first-child {
                &::before {
                    display: none;
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

/*================================================
Products Collections Area CSS
=================================================*/
.products-collections-area {
    padding-bottom: 30px;
    
    .container-fluid {
        width: 90%;
    }
}
.collections-box {
    overflow: hidden;
    position: relative;
    text-align: center;
    margin-bottom: 30px;

    img {
        width: 100%;
    }

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 100%;
        background-color: $white-color;
        opacity: 0.4;
        transition: $transition;
        z-index: 2;
    }
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: -100%;
        background-color: $white-color;
        opacity: 0.4;
        transition: $transition;
        z-index: 2;
    }
    .category {
        position: absolute;
        left: 0;
        transition: $transition;
        right: 0;
        bottom: 25px;
        border: 1px solid #ececec;
        padding: 20px;
        margin: {
            left: 40px;
            right: 40px;
        }
        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background: #ededed;
            opacity: 0.94;
            z-index: 1;
            margin: 6px;
        }
        h4 {
            font-size: 16px;
            text-transform: uppercase;
            margin-bottom: 7px;
            position: relative;
            z-index: 1;
        }
        span {
            color: $paragraph-color;
            position: relative;
            display: block;
            z-index: 1;
        }
    }
    img {
        transition: $transition;
    }
    a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
    }
    &:hover, &:focus {
        img {
            transform: scale(1.3);
        }
        &::after {
            left: 100%;
        }
        &::before {
            left: -100%;
        }
    }
}
.single-collections-box {
    overflow: hidden;
    position: relative;
    text-align: center;
    margin-bottom: 30px;

    img {
        width: 100%;
    }

    .category {
        position: absolute;
        left: 0;
        transition: $transition;
        right: 0;
        bottom: 0;
        background-color: $white-color;
        padding: 20px;
        margin: {
            left: 10px;
            right: 10px;
        }
        h4 {
            font-size: 16px;
            text-transform: uppercase;
            margin-bottom: 7px;
            position: relative;
            z-index: 1;
        }
        span {
            color: $paragraph-color;
            position: relative;
            display: block;
            z-index: 1;
        }
    }
    img {
        transition: $transition;
    }
    a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
    }
    &:hover, &:focus {
        img {
            transform: scale(1.3);
        }
        .category {
            bottom: 10px;
        }
    }
}
.products-filter-options {
    margin-bottom: 30px;

    span {
        color: $black-color;
        display: inline-block;
        padding-right: 5px;

        a {
            text-transform: uppercase;
            color: $black-color;
            font-weight: 700;
            margin-right: 20px;

            i {
                font-size: 17px;
            }
        }
    }
    .view-list-row {
        position: relative;
        top: 2.4px;

        .view-column {
            a {
                display: inline-block;
                margin-right: 3px;
            }
            .icon-view-two {
                span {
                    cursor: pointer;
                    width: 7px;
                    height: 14px;
                    display: block;
                    float: left;
                    border: 1px solid #d0d0d0;
                    transition: border .2s;
        
                    &:not(:first-child) {
                        margin-left: 1px;
                    }
                }
                &.active, &:hover {
                    span {
                        border-color: $black-color;
                    }
                }
            }
            .icon-view-three {
                margin-left: 1px;

                span {
                    width: 7px;
                    height: 14px;
                    display: block;
                    float: left;
                    border: 1px solid #d0d0d0;
                    transition: border .2s;

                    &:not(:first-child) {
                        margin-left: 1px;
                    }
                }
                &.active, &:hover {
                    span {
                        border-color: $black-color;
                    }
                }
            }
            .icon-view-four {
                margin-left: 1px;

                span {
                    width: 7px;
                    height: 14px;
                    display: block;
                    float: left;
                    border: 1px solid #d0d0d0;
                    transition: border .2s;

                    &:not(:first-child) {
                        margin-left: 1px;
                    }
                }
                &.active, &:hover {
                    span {
                        border-color: $black-color;
                    }
                }
            }
            .icon-view-five {
                margin-left: 1px;

                span {
                    width: 5px;
                    height: 14px;
                    display: block;
                    float: left;
                    border: 1px solid #d0d0d0;
                    transition: border .2s;

                    &:not(:first-child) {
                        margin-left: 1px;
                    }
                }
                &.active, &:hover {
                    span {
                        border-color: $black-color;
                    }
                }
            }
            .icon-view-six {
                margin-left: 1px;

                span {
                    width: 3px;
                    height: 14px;
                    display: block;
                    float: left;
                    border: 1px solid #d0d0d0;
                    transition: border .2s;

                    &:not(:first-child) {
                        margin-left: 1px;
                    }
                }
                &.active, &:hover {
                    span {
                        border-color: $black-color;
                    }
                }
            }
            .view-grid-switch {
                margin-left: 1px;

                span {
                    width: 24px;
                    height: 6px;
                    display: block;
                    position: relative;
                    background-color: #d0d0d0;
                    transition: background-color .2s;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 6px;
                        background-color: $white-color;
                        display: block;
                        width: 2px;
                        height: 6px;
                    }
                    &:not(:first-child) {
                        margin-top: 2px;
                    }
                }
                &.active, &:hover {
                    span {
                        background-color: $black-color;
                    }
                }
            }
        }
    }
    p {
        text-align: center;
        font-size: 13px;
    }
    .show-products-number {
        select {
            background: transparent;
            outline: 0;
            padding: 0;
            border: none;
            cursor: pointer;
        }
    }
    .products-ordering-list {
        select {
            background: transparent;
            outline: 0;
            padding: 0;
            border: none;
            cursor: pointer;
        }
    }
    .col {
        &:last-child {
            -ms-flex-pack: end;
            -moz-justify-content: flex-end;
            justify-content: flex-end;
        }
    }
}
.products-collections-listing {
    &.products-col-two {
        .products-col-item {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
        .single-product-box {
            .product-image {
                a {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
    &.products-col-three {
        .products-col-item {
            -ms-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
        }
        .single-product-box {
            .product-image {
                a {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
    &.products-col-four {
        .products-col-item {
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
        }
    }
    &.products-row-view {
        .products-col-item {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
        .col-lg-3 {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
        .col-lg-2 {
            -ms-flex: 0 0 33.3333%;
            flex: 0 0 33.3333%;
            max-width: 33.3333%;
        }
        .single-product-box {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -ms-flex-align: center!important;
            align-items: center!important;
            background-color: #fdfdfd;

            .product-content {
                text-align: left;
                padding-left: 25px;
            }
        }
    }
    .col-lg-2 {
        .single-product-box {
            .product-image {
                a {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}

/*================================================
Woocommerce Sidebar Area CSS
=================================================*/
.woocommerce-sidebar-area {
    .collapse-widget {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
        .collapse-widget-title {
            background-color: #f5f5f5;
            font-size: 15px;
            text-transform: uppercase;
            margin-bottom: 0;
            cursor: pointer;
            position: relative;
            padding: 15px;

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 35px;
                height: 2px;
                background: #eeeeee;
                transition: $transition;
            }
            i {
                position: absolute;
                right: 15px;
                font-size: 15px;
                transition: $transition;
                color: #858585;
            }
            &:hover, &:focus {
                &::before {
                    width: 40px;
                }
            }
            &.active {
                i {
                    transform: rotate(-180deg);
                }
            }
        }
        .aside-widget-title {
            font-size: 16px;
            text-transform: uppercase;
            margin-bottom: 0;
            position: relative;
            padding-bottom: 7px;

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 35px;
                height: 2px;
                background: #eeeeee;
                transition: $transition;
            }
            i {
                position: relative;
                left: 2px;
                font-size: 15px;
                transition: $transition;
                color: #858585;
            }
        }
        .selected-filters-wrap-list {
            ul {
                list-style-type: none;
                margin: {
                    bottom: 0;
                    top: -10px;
                    left: -10px;
                }
                padding: {
                    left: 0;
                    top: 20px;
                }
                li {
                    display: inline-block;
                    margin: {
                        left: 10px;
                        top: 10px;
                    }
                    a {
                        font-size: 12px;
                        display: inline-block;
                        border: 1px solid #eeeeee;
                        padding: 4px 26px 4px 12px;
                        color: #858585;
                        position: relative;
                        text-align: center;

                        &:hover, &:focus {
                            border-color: $black-color;
                            color: $black-color;

                            &::before {
                                color: red;
                            }
                        }
                        &::before {
                            content: 'x';
                            position: absolute;
                            right: 12px;
                            top: 50%;
                            transition: $transition;
                            transform: translateY(-50%);                        
                        }
                    }
                    &.active {
                        a {
                            border-color: $black-color;
                            color: $black-color;
                        }
                    }
                }
            }
            .delete-selected-filters {
                margin-top: 12px;
                cursor: pointer;
    
                a {
                    color: $paragraph-color;
                    
                    i {
                        transition: $transition;
                    }
                    span {
                        display: inline-block;
                        font-size: 12px;
                        color: $paragraph-color;
                        text-decoration: underline;
                    }
                    &:hover, &:focus {
                        i {
                            color: red;
                        }
                    }
                }
            }
        }
        .collections-list-row {
            list-style-type: none;
            margin-bottom: 0;
            padding: {
                left: 0;
                top: 20px;
            }
            li {
                margin-bottom: 12px;

                a {
                    color: #858585;

                    &:hover, &:focus {
                        color: $black-color;
                    }
                }
                &.active {
                    a {
                        color: $black-color;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .brands-list-row {
            list-style-type: none;
            margin-bottom: 0;
            padding: {
                left: 0;
                top: 20px;
            }
            li {
                margin-bottom: 12px;

                a {
                    color: #858585;

                    &:hover, &:focus {
                        color: $black-color;
                    }
                }
                &.active {
                    a {
                        color: $black-color;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .size-list-row {
            list-style-type: none;
            margin: {
                bottom: 0;
                top: -10px;
                left: -10px;
            }
            padding: {
                left: 0;
                top: 20px;
            }
            li {
                display: inline-block;
                margin: {
                    left: 10px;
                    top: 10px;
                }
                a {
                    width: 35px;
                    height: 35px;
                    display: inline-block;
                    line-height: 35px;
                    border: 1px solid #eeeeee;
                    color: #858585;
                    text-align: center;

                    &:hover, &:focus {
                        border-color: $black-color;
                        color: $black-color;
                    }
                }
                &.active {
                    a {
                        border-color: $black-color;
                        color: $black-color;
                    }
                }
            }
        }
        .price-list-row {
            list-style-type: none;
            margin-bottom: 0;
            padding: {
                left: 0;
                top: 20px;
            }
            li {
                margin-bottom: 10px;

                a {
                    color: #858585;

                    &:hover, &:focus {
                        color: $black-color;
                    }
                }
                &.active {
                    a {
                        color: $black-color;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .color-list-row {
            list-style-type: none;
            margin: {
                bottom: 0;
                top: -4px;
                left: -4px;
            }
            padding: {
                left: 0;
                top: 20px;
            }
            li {
                display: inline-block;
                margin: {
                    left: 4px;
                    top: 4px;
                }
                a {
                    display: inline-block;
                    position: relative;
                    border: 1px solid transparent;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;

                    &::before {
                        position: absolute;
                        content: '';
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        background: #eeeeee;
                        border-radius: 50%;
                        margin: 2px;
                        transition: $transition;
                    }
                    &:hover, &:focus {
                        border-color: $black-color;
                    }
                    &.color-white {
                        &::before {
                            background: $white-color;
                            border: 1px solid #eeeeee;
                        }
                    }
                    &.color-black {
                        &::before {
                            background: $black-color;
                        }
                    }
                    &.color-red {
                        &::before {
                            background: red;
                        }
                    }
                    &.color-blue {
                        &::before {
                            background: blue;
                        }
                    }
                    &.color-green {
                        &::before {
                            background: green;
                        }
                    }
                    &.color-yellow {
                        &::before {
                            background: yellow;
                        }
                    }
                    &.color-yellowgreen {
                        &::before {
                            background: yellowgreen;
                        }
                    }
                    &.color-pink {
                        &::before {
                            background: pink;
                        }
                    }
                    &.color-violet {
                        &::before {
                            background: violet;
                        }
                    }
                    &.color-teal {
                        &::before {
                            background: teal;
                        }
                    }
                    &.color-plum {
                        &::before {
                            background: plum;
                        }
                    }
                    &.color-lime {
                        &::before {
                            background: lime;
                        }
                    }
                    &.color-blueviolet {
                        &::before {
                            background: blueviolet;
                        }
                    }
                }
                &.active {
                    a {
                        border-color: $black-color;
                    }
                }
            }
        }
        .tags-list-row {
            list-style-type: none;
            margin: {
                bottom: 0;
                top: -10px;
                left: -10px;
            }
            padding: {
                left: 0;
                top: 20px;
            }
            li {
                display: inline-block;
                margin: {
                    left: 10px;
                    top: 10px;
                }
                a {
                    display: inline-block;
                    border: 1px solid #eeeeee;
                    padding: 5px 15px;
                    color: #858585;
                    text-align: center;

                    &:hover, &:focus {
                        border-color: $black-color;
                        color: $black-color;
                    }
                }
                &.active {
                    a {
                        border-color: $black-color;
                        color: $black-color;
                    }
                }
            }
        }
        .aside-single-products {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin-top: 20px;
            -ms-flex-align: center!important;
            align-items: center!important;

            .products-image {
                overflow: hidden;
                width: 100px;
                margin-right: 15px;
                position: relative;

                a {
                    img {
                        transition: $transition;
                    }
                }
            }
            .products-content {
                span {
                    a {
                        color: $paragraph-color;

                        &:hover {
                            color: $black-color;
                        }
                    }
                }
                h3 {
                    font-size: 17px;
                    margin: {
                        bottom: 0;
                        top: 6px;
                    }
                    a {
                        color: $black-color;
        
                        &:hover, &:focus {
                            color: $black-color;
                        }
                    }
                }
                .product-price {
                    margin: {
                        top: 5px;
                    }
                    span {
                        font-size: 17px;
                        display: inline-block;
                    }
                    .old-price {
                        color: #858585;
                        font-size: 15px;
                        text-decoration: line-through;
                    }
                }
            }
            &:hover, &:focus {
                .products-image {
                    a {
                        img {
                            transform: scale(1.2);
                        }
                    }
                }
            }
        }
        .aside-trending-products {
            overflow: hidden;
            position: relative;
            text-align: center;
            margin-top: 25px;
        
            .category {
                position: absolute;
                left: 0;
                transition: $transition;
                right: 0;
                bottom: 0;
                background-color: $white-color;
                padding: 20px;
                margin: {
                    left: 10px;
                    right: 10px;
                }
                h4 {
                    font-size: 16px;
                    text-transform: uppercase;
                    margin-bottom: 7px;
                    position: relative;
                    z-index: 1;
                }
                span {
                    color: $paragraph-color;
                    position: relative;
                    display: block;
                    z-index: 1;
                }
            }
            img {
                transition: $transition;
                width: 100%;
            }
            a {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 3;
            }
            &:hover, &:focus {
                img {
                    transform: scale(1.3);
                }
                .category {
                    bottom: 10px;
                }
            }
            &:first-child {
                margin-top: 0;
            }
        }
        .collection-filter-by-price {
            margin-top: 20px;

            .irs-handle {
                cursor: pointer;
            }
            .irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single {
                background-color: $black-color;
                padding: 1px 5px 1px 11.5px;

                &::before {
                    border-top-color: $black-color;
                }
                &::after {
                    content: '$';
                    position: absolute;
                    left: 5px;
                    top: 0.5px;
                }
            }
            .irs--flat .irs-bar {
                background-color: $black-color;
            }
            .irs--flat .irs-handle>i:first-child {
                background-color: $black-color;
            }
            .irs--flat .irs-min, .irs--flat .irs-max {
                padding: 1px 3px 1px 9.5px;

                &::before {
                    content: '$';
                    position: absolute;
                    left: 3px;
                    top: 0.5px;
                }
            }
        }
    }
}

/*================================================
Products Filter Modal Area CSS
=================================================*/
.productsFilterModal {
    &.modal {
        &.left {
            .modal-dialog {
                position: fixed;
                margin: auto;
                width: 400px;
                height: 100%;
                transform: translate3d(0%, 0, 0);
            }
            &.fade {
                .modal-dialog {
                    left: -320px;
                    transition: opacity 0.3s linear, left 0.3s ease-out;
                }
                &.show {
                    .modal-dialog {
                        left: 0;
                    }
                }
            }
        }
    }
    .modal-content {
        height: 100%;
        overflow-y: auto;
        border-radius: 0;
        border: none;
        position: relative;
        padding: {
            top: 60px;
            bottom: 20px;
            left: 20px;
            right: 20px;
        }
        .modal-body {
            padding: 0;
        }
    }
    button {
        &.close {
            position: absolute;
            left: 20px;
            top: 20px;
            outline: 0;
            border: 1px solid #eee;
            text-shadow: unset;
            color: $paragraph-color;
            opacity: 1;
            line-height: initial;
            font-size: 14px;
            transition: $transition;
            padding: 5px 10px;

            i {
                transition: $transition;
            }
            &:hover, &:focus {
                color: $black-color;

                i {
                    color: red;
                }
            }
        }
    }
}

/*================================================
Gallery Area CSS
=================================================*/
.single-gallery-item {
    position: relative;
    text-align: center;

    a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }
    .gallery-content {
        position: absolute;
        bottom: 0;
        left: 10px;
        border: 1px solid $white-color;
        right: 10px;
        padding: 25px 15px;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        transition: $transition;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            margin: 5px;
            background-color: $white-color;
            z-index: -1;
        }
        span {
            display: block;
            color: $paragraph-color;
            font-size: 13px;
        }
        h3 {
            font-size: 18px;
            margin: {
                bottom: 0;
                top: 5px;
            }
        }
    }
    &:hover, &:focus {
        .gallery-content {
            bottom: 10px;
            opacity: 1;
            visibility: visible;
        }
    }
}

/*================================================
Look Book Area CSS
=================================================*/
.lookbook-area {
    padding-bottom: 30px;
}
.single-lookbook-box {
    position: relative;
    margin-bottom: 30px;
                         
    .category {
        transition: $transition;
        background-color: $white-color;
        padding: 15px;
        position: relative;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        margin: {
            left: 10px;
            top: -10px;
            right: 10px;
        }
        span {
            display: block;
            margin-bottom: 7px;
            font: {
                size: 13px;
                weight: 600;
            }
            a {
                display: inline-block;
                color: $black-color;
                padding-right: 3px;
                font-weight: normal;

                &:hover {
                    color: $black-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

/*================================================
Sizing Guide Area CSS
=================================================*/
.sizing-guide-table {
    h3 {
        font-size: 18px;
        margin-bottom: 20px;
    }
    table {
        margin-bottom: 0;

        thead {
            background-color: $black-color;
            color: $white-color;

            th {
                border-bottom: none;
                color: $white-color;
            }
        }
        tr {
            td, th {
                border-top: none;
            }
        }
    }
    .sizing-guide-info {
        padding-left: 0;
        list-style-type: none;
        margin: {
            top: 30px;
            bottom: 0;
        }
        li {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
            span {
                font-weight: 700;
            }
        }
    }
    &:not(:first-child) {
        margin-top: 30px;
    }
}

/*================================================
404 Error Area CSS
=================================================*/
.error-content {
    text-align: center;
    margin: 0 auto;
    max-width: 520px;

    h3 {
        font-size: 30px;
        margin: {
            top: 30px;
            bottom: 15px;
        }
    }
    p {
        margin-bottom: 25px;
    }
}

/*================================================
FAQs Area CSS
=================================================*/
.faq-accordion {
    .accordion {
        list-style-type: none;
        padding: 0;
        margin: 0;
        border: none;

        .accordion__button {
            font-weight: 500;
        }
        .accordion__panel {
            ul {
                padding-left: 17px;
                margin: 0 10px;

                li {
                    margin-bottom: 10px;
                    line-height: 1.7;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .accordion-item {
            display: block;
            margin-bottom: 15px;
            border-bottom: 1px dashed #eeeeee;
            padding-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
                border-bottom: none;
                padding-bottom: 0;
            }
            .accordion-title {
                position: relative;
                color: $black-color;
                font-size: 17px;
                padding-right: 20px;

                i {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 14px;
                }
                &.active {
                    i {
                        &::before {
                            content: "\f068";
                        }
                    }
                }
            }
            .accordion-content {
                display: none;
                margin-top: 20px;

                &.show {
                    display: block;
                }
                ul {
                    padding-left: 0;
                    list-style-type: none;
                    margin-bottom: 0;

                    li {
                        color: $paragraph-color;
                        position: relative;
                        margin-bottom: 8px;
                        line-height: 25px;
                        padding-left: 12px;

                        &::before {
                            content: '';
                            position: absolute;
                            top: 10px;
                            left: 0;
                            width: 5px;
                            height: 5px;
                            background: $black-color;
                            border-radius: 50%;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                        a {
                            display: inline-block;
                            color: $paragraph-color;

                            &:hover {
                                color: $black-color;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*================================================
Contact Area CSS
=================================================*/
.contact-info {
    h3 {
        font-size: 18px;
        margin-bottom: 10px;
    }
    p {
        margin-bottom: 0;
    }
    .contact-list {
        list-style-type: none;
        padding-left: 0;
        margin: {
            top: 20px;
            bottom: 25px;
        }
        li {
            color: $paragraph-color;
            margin-bottom: 10px;
            font-size: 13px;
            position: relative;
            padding-left: 20px;

            i {
                color: $black-color;
                position: absolute;
                left: 0;
                top: 2px;
            }
            a {
                color: $paragraph-color;
                display: inline-block;

                &:hover, &:focus {
                    color: $black-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .opening-hours {
        padding-left: 0;
        list-style-type: none;
        margin: {
            bottom: 25px;
            top: 15px;
        }
        li {
            margin-bottom: 8px;
            color: $paragraph-color;
            font-size: 13px;

            span {
                color: $black-color;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .social {
        padding-left: 0;
        list-style-type: none;
        margin: {
            bottom: 0;
            top: 15px;
        }
        li {
            display: inline-block;
            margin-right: 4px;
            
            a {
                color: $paragraph-color;
                border: 1px solid #eeeeee;
                width: 35px;
                height: 35px;
                font-size: 13px;
                line-height: 35px;
                text-align: center;
                border-radius: 50%;

                &:hover, &:focus {
                    background-color: $black-color;
                    color: $white-color;
                    border-color: $black-color;
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
.contact-form {
    h3 {
        font-size: 18px;
        margin-bottom: 10px;
    }
    p {
        margin-bottom: 0;
    }
    #contactForm {
        margin-top: 20px;

        label {
            font-size: 13px;
            text-transform: uppercase;
            color: $paragraph-color;

            span {
                text-transform: lowercase;
            }
        }
        .form-group {
            margin-bottom: 20px;
        }
        .form-control {
            font-size: 13px;
            height: 40px;
            border: 1px solid #eeeeee;
            transition: $transition;

            &:focus {
                border-color: $black-color;
                background-color: transparent;
            }
        }
        textarea {
            height: auto !important;
            padding-top: 15px;
        }
        .list-unstyled {
            padding-left: 0;
            list-style-type: none;
            margin: {
                bottom: 0;
                top: 8px;
            }
            li {
                font-size: 13px;
                color: red;
            }
        }
        #msgSubmit {
            margin: 0;
            font-size: 18px;

            &.text-danger, &.text-success {
                margin-top: 15px;
            }
        }
    }
}
#map {
    height: 400px;
    width: 100%;
}

/*================================================
Products Details Area CSS
=================================================*/
.products-page-gallery {
    text-align: center;

    .product-page-gallery-main {
        .slick-slide {
            img {
                width: 100%;
            }
        }
        .slick-prev, .slick-next {
            z-index: 1;
            width: 45px;
            height: 45px;
            line-height: 60px;
            padding: 0;
            background-color: $white-color;
            border-radius: 50%;
            text-align: center;
            transition: $transition;
            opacity: 0;
            visibility: hidden;

            &::before {
                content: '' !important;
                position: relative;
                display: inline-block;
                width: .8rem;
                height: .8rem;
                border: 0.125rem solid;
                line-height: initial;
                opacity: 1;
                transition: $transition;
                border: {
                    bottom: 0;
                    left: 0;
                    radius: 1px;
                    color: $black-color;
                }
            }
            &:hover {
                background-color: $black-color;
                color: $white-color;

                &::before {
                    border-color: $white-color;
                }
            }
        }
        .slick-next {
            padding-right: 5px;
            right: 0;

            &::before {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);    
            }
        }
        .slick-prev {
            padding-left: 5px;
            left: 0;

            &::before {
                -webkit-transform: rotate(225deg);
                -moz-transform: rotate(225deg);
                -ms-transform: rotate(225deg);
                -o-transform: rotate(225deg);
                transform: rotate(225deg);
            }
        }
        &:hover {
            .slick-prev, .slick-next {
                opacity: 1;
                visibility: visible;
            }
            .slick-next {
                right: 15px;
            }
            .slick-prev {
                left: 15px;
            }
        }
    }
    .slick-slide {
        outline: 0 !important;
    }
    .product-page-gallery-preview {
        margin: {
            top: 10px;
            left: -5px;
            right: -5px;
        }
        .slick-slide {
            margin: 0 5px;
            cursor: pointer;

            span {
                display: block;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: 2px solid $black-color;
                    opacity: 0;
                    visibility: hidden;
                    transition: $transition;
                }
                &:hover, &:focus {
                    &::before {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
            &.slick-current {
                &.slick-active {
                    span {
                        &::before {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
.product-details-content {
    h3 {
        font-size: 22px;
        margin-bottom: 8px;

        a {
            color: $black-color;

            &:hover {
                color: $black-color;
            }
        }
    }
    p {
        margin-bottom: 0;
    }
    .price {
        margin-bottom: 5px;
        
        span {
            display: inline-block;
            font-size: 18px;
            color: $black-color;
        }
    }
    .product-review {
        margin-bottom: 12px;

        .rating {
            display: inline-block;
            padding-right: 8px;

            i {
                color: #ffba0a;
                margin-right: 2px;
            }
        }
        .rating-count {
            display: inline-block;
            color: $black-color;
            border-bottom: 1px solid $black-color;
            line-height: initial;
        }
    }
    .product-info {
        list-style-type: none;
        padding: 0;
        margin: {
            top: 15px;
            bottom: 0;
        }
        li {
            font-size: 16px;
            color: $black-color;
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }
            span {
                color: $paragraph-color;
            }
            a {
                display: inline-block;
                font-weight: 500;
                color: $black-color;

                &:hover {
                    color: $black-color;
                }
            }
        }
    }
    .wishlist-compare-btn {
        margin-top: 20px;

        .btn {
            i {
                margin-right: 3px;
            }
            &:not(:first-child) {
                margin-left: 10px;
            }
        }
    }
    .product-color-switch {
        margin-top: 8px;

        h4 {
            font-size: 16px;
            color: $paragraph-color;
            margin-bottom: 6px;
        }
        ul {
            list-style-type: none;
            margin: {
                bottom: 0;
                top: -4px;
                left: -4px;
            }
            padding: {
                left: 0;
            }
            li {
                display: inline-block;
                margin: {
                    left: 4px;
                    top: 4px;
                }
                a {
                    display: inline-block;
                    position: relative;
                    border: 1px solid transparent;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;

                    &::before {
                        position: absolute;
                        content: '';
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        background: #eeeeee;
                        border-radius: 50%;
                        margin: 2px;
                        transition: $transition;
                    }
                    &:hover, &:focus {
                        border-color: $black-color;
                    }
                    &.color-white {
                        &::before {
                            background: $white-color;
                            border: 1px solid #eeeeee;
                        }
                    }
                    &.color-black {
                        &::before {
                            background: $black-color;
                        }
                    }
                    &.color-red {
                        &::before {
                            background: red;
                        }
                    }
                    &.color-blue {
                        &::before {
                            background: blue;
                        }
                    }
                    &.color-green {
                        &::before {
                            background: green;
                        }
                    }
                    &.color-yellow {
                        &::before {
                            background: yellow;
                        }
                    }
                    &.color-yellowgreen {
                        &::before {
                            background: yellowgreen;
                        }
                    }
                    &.color-pink {
                        &::before {
                            background: pink;
                        }
                    }
                    &.color-violet {
                        &::before {
                            background: violet;
                        }
                    }
                    &.color-teal {
                        &::before {
                            background: teal;
                        }
                    }
                    &.color-plum {
                        &::before {
                            background: plum;
                        }
                    }
                    &.color-lime {
                        &::before {
                            background: lime;
                        }
                    }
                    &.color-blueviolet {
                        &::before {
                            background: blueviolet;
                        }
                    }
                }
                &.active {
                    a {
                        border-color: $black-color;
                    }
                }
            }
        }
    }
    .product-size-wrapper {
        margin: {
            top: 8px;
            bottom: 25px;
        }
        h4 {
            font-size: 16px;
            color: $paragraph-color;
            margin-bottom: 8px;
        }
        ul {
            list-style-type: none;
            margin: {
                bottom: 0;
                top: -8px;
                left: -8px;
            }
            padding: {
                left: 0;
            }
            li {
                display: inline-block;
                margin: {
                    left: 8px;
                    top: 8px;
                }
                a {
                    width: 40px;
                    height: 35px;
                    display: inline-block;
                    line-height: 35px;
                    border: 1px solid #eeeeee;
                    color: #858585;
                    text-align: center;

                    &:hover, &:focus {
                        border-color: $black-color;
                        color: $black-color;
                    }
                }
                &.active {
                    a {
                        border-color: $black-color;
                        color: $black-color;
                    }
                }
            }
        }
    }
    .product-add-to-cart {
        .input-counter {
            max-width: 130px;
            min-width: 130px;
            margin-right: 10px;
            text-align: center;
            display: inline-block;
            position: relative;    

            span {
                position: absolute;
                top: 0;
                background-color: transparent;
                cursor: pointer;
                color: #d0d0d0;
                width: 40px;
                height: 100%;
                line-height: 50px;
                transition: $transition;

                &.minus-btn {
                    left: 0;
                }
                &.plus-btn {
                    right: 0;
                }
                &:hover {
                    color: $black-color;
                }
            }
            input {
                height: 45px;
                color: $black-color;
                outline: 0;
                display: block;
                border: none;
                background-color: #f8f8f8;
                text-align: center;
                width: 100%;
                font: {
                    size: 17px;
                    weight: 600;
                }
                &::placeholder {
                    color: $black-color;
                }
            }
        }
        .btn {
            height: 45px;
            top: -2px;
            position: relative;

            i {
                margin-right: 2px;
            }
        }
    }
    .product-info-btn {
        margin-bottom: 25px;

        a {
            color: $paragraph-color;
            text-transform: uppercase;
            font-weight: 600;
            display: inline-block;
            position: relative;
            line-height: initial;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: -2px;
                width: 0;
                height: 1.5px;
                background: $black-color;
                transition: $transition;
            }
            i {
                color: $black-color;
                margin-right: 3px;
                font-size: 15px;
            }
            &:hover {
                color: $black-color;

                &::before {
                    width: 100%;
                }
            }
            &:not(:first-child) {
                margin-left: 15px;
            }
        }
    }
    .buy-checkbox-btn {
        margin-top: 25px;

        input {
            display: none;
        }
        .cbx {
            margin: auto;
            -webkit-user-select: none;
            user-select: none;
            cursor: pointer;

            span {
                display: inline-block;
                vertical-align: middle;
                transform: translate3d(0, 0, 0);

                &:first-child {
                    position: relative;
                    width: 18px;
                    height: 18px;
                    border-radius: 3px;
                    transform: scale(1);
                    vertical-align: middle;
                    border: 1px solid #ebebeb;
                    transition: all 0.2s ease;
                    transition: $transition;

                    svg {
                        position: absolute;
                        top: 3px;
                        left: 2px;
                        fill: none;
                        stroke: $white-color;
                        stroke-width: 2;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-dasharray: 16px;
                        stroke-dashoffset: 16px;
                        transition: all 0.3s ease;
                        transition-delay: 0.1s;
                        transform: translate3d(0, 0, 0);
                        transition: $transition;
                    }
                    &:before {
                        content: "";
                        width: 100%;
                        height: 100%;
                        background: $black-color;
                        display: block;
                        transform: scale(0);
                        opacity: 1;
                        border-radius: 50%;
                        transition: $transition;
                    }
                }
                &:last-child {
                    padding-left: 10px;
                    color: $paragraph-color;
                }
            }
            &:hover {
                span {
                    &:first-child {
                        border-color: $black-color;
                    }
                }
            }
        }
        .inp-cbx:checked + .cbx span:first-child {
            background: $black-color;
            border-color: $black-color;
            animation: wave 0.4s ease;
        }
        .inp-cbx:checked + .cbx span:first-child svg {
            stroke-dashoffset: 0;
        }
        .inp-cbx:checked + .cbx span:first-child:before {
            transform: scale(3.5);
            opacity: 0;
            transition: all 0.6s ease;
        }
        .item {
            &:not(:first-child) {
                margin-top: 15px;
            }
        }
        .btn {
            display: block;
        }
    }
    .custom-payment-options {
        margin-top: 20px;

        span {
            display: block;
            color: $paragraph-color;
            margin-bottom: 5px;
        }
        .payment-methods {
            a {
                display: inline-block;
                margin-right: 5px;
                margin-top: 5px;
                img {
                    width: 40px;
                }
            }
        }
    }
}
.products-details-tab {
    margin-top: -80px;

    .tabs {
        list-style-type: none;
        margin: 0 0 30px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        text-align: center;
        padding: 0;
        border-bottom: 1px dashed #eeeeee;
        padding-bottom: 15px;

        li {
            display: block;
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;

            .tabs-nav-text, a {
                display: inline-block;
                font-size: 14px;
                position: relative;
                padding-left: 18px;
                text-transform: uppercase;
                color: $paragraph-color;
                cursor: pointer;
        
                .dot {
                    position: absolute;
                    top: 4px;
                    left: 0;
                    width: 12px;
                    height: 12px;
                    border: 1px solid $paragraph-color;
                    transition: $transition;
        
                    &::before {
                        position: absolute;
                        top: 0;
                        content: '';
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: $paragraph-color;
                        margin: 1px;
                        transition: $transition;
                    }
                }
                &:hover, &:focus {
                    color: $black-color;

                    .dot {
                        border-color: $black-color;

                        &::before {
                            background: $black-color;
                        }
                    }
                }
            }
            &.current {
                .tabs-nav-text, a {
                    color: $black-color;

                    .dot {
                        border-color: $black-color;

                        &::before {
                            background: $black-color;
                        }
                    }
                }
            }
        }
    }
    .tab_content {
        .tabs_item {
            .products-details-tab-content {
                p {
                    margin-bottom: 20px;
                }
                ul, ol {
                    padding-left: 0;
                    list-style-type: none;
                    margin-bottom: 0;

                    li {
                        margin-bottom: 10px;
                        position: relative;
                        padding-left: 12px;
                        color: $paragraph-color;

                        &::before {
                            content: '';
                            position: absolute;
                            top: 7px;
                            left: 0;
                            width: 5px;
                            height: 5px;
                            background: $black-color;
                            border-radius: 50%;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                .table {
                    margin-bottom: 0;

                    &.table-striped {
                        tbody {
                            tr {
                                td {
                                    border-top: none;
                                }
                            }
                        }
                    }
                    &.table-bordered {
                        tbody {
                            tr {
                                td {
                                    vertical-align: middle;
                                    color: $paragraph-color;
                                }
                            }
                        }
                    }
                }
                h3 {
                    font-size: 18px;
                    margin-bottom: 15px;
                }
                .product-review-form {
                    .review-title {
                        position: relative;

                        .rating {
                            display: inline-block;
                            .fas.fa-star {
                                color: #ffba0a;
                            }
                            i {
                                color: #ebebeb;
                                margin-right: 2px;
                            }
                        }
                        p {
                            margin-bottom: 0;
                            display: inline-block;
                            padding-left: 5px;
                        }
                        .btn {
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                    .review-comments {
                        margin-top: 35px;
                        
                        .review-item {
                            margin-top: 30px;
                            position: relative;
                            padding-right: 200px;
                            border-top: 1px dashed #eeeeee;
                            padding-top: 30px;

                            .rating {
                                .fas.fa-star {
                                    color: #ffba0a;
                                }
                                i {
                                    color: #ebebeb;
                                }
                            }
                            h3 {
                                margin: {
                                    top: 10px;
                                    bottom: 8px;
                                }
                            }
                            span {
                                margin-bottom: 10px;
                                font-size: 13px;
                                display: block;

                                strong {
                                    font-weight: 600;
                                }
                            }
                            p {
                                margin-bottom: 0;
                            }
                            .review-report-link {
                                position: absolute;
                                right: 20px;
                                color: $paragraph-color;
                                top: 40px;
                                text-decoration: underline;

                                &:hover {
                                    color: $black-color;
                                }
                            }
                        }
                    }
                    .review-form {
                        margin-top: 35px;

                        form {
                            .form-group {
                                label {
                                    font-size: 13px;
                                    color: $paragraph-color;
                                    text-transform: uppercase;
                                }
                                textarea {
                                    padding-top: 15px;
                                }
                            }
                            .btn {
                                margin-top: 10px;
                            }
                            .review-rating {
                                margin-bottom: 1rem;

                                p {
                                    margin-bottom: 5px;
                                }
                                .star-source {
                                    width: 0;
                                    height: 0;
                                    margin-top: 5px;
                                    visibility: hidden;
                    
                                    svg {
                                        width: 1.5rem;
                                        height: 1.5rem;
                                        padding: 0 0.15rem;
                                    }
                                }
                                .star-rating {
                                    display: flex;
                                    flex-direction: row-reverse;
                                    justify-content: center;
                                    width: 105px;
                    
                                    label {
                                        cursor: pointer;
                                        margin-bottom: 0;
                                        
                                        .star {
                                            color: transparent;
                                            transition: color 0.2s ease-in-out;
                                        }
                                    }
                                    label:hover ~ label .star, svg.star:hover, input[name="star"]:focus ~ label .star, input[name="star"]:checked ~ label .star {
                                        color: #f2b01e;
                                    }
                                    svg {
                                        width: 1.5rem;
                                        height: 1.5rem;
                                        padding: 0 0.15rem;
                                    }
                                    input {
                                        &[name="star"] {
                                            display: inline-block;
                                            width: 0;
                                            opacity: 0;
                                            margin-left: -2px;
                                        }
                                        &[name="star"]:checked {
                                            + label {
                                                animation: scaleup 1s;
                    
                                                .star {
                                                    animation: starred 0.5s;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.sizeGuideModal {
    .modal-dialog {
        max-width: 900px;
        margin: 0 auto;
    }
    .modal-content {
        border: none;
        padding: 40px;
        border-radius: 0;

        button {
            &.close {
                position: absolute;
                right: 0;
                top: 0;
                outline: 0;
                opacity: 1;
                color: $black-color;
                transition: $transition;
                line-height: 35px;
                padding: 0;
                margin: 0;
                font-size: 20px;
                width: 35px;
                text-shadow: unset;
                height: 35px;

                &:hover {
                    background-color: red;
                    color: $white-color;
                }
            }
        }
        .modal-sizeguide {
            text-align: center;

            h3 {
                font-size: 18px;
                margin-bottom: 8px;
            }
            p {
                margin-bottom: 25px;
            }
            .table {
                margin-bottom: 0;

                thead {
                    background-color: $black-color;
                    color: $white-color;
        
                    th {
                        border-bottom: none;
                        color: $white-color;
                    }
                }
                tr {
                    td, th {
                        border-top: none;
                    }
                }
            }
        }
    }
}
.productShippingModal {
    .modal-dialog {
        max-width: 900px;
        margin: 0 auto;
    }
    .modal-content {
        border: none;
        padding: 40px;
        border-radius: 0;

        button {
            &.close {
                position: absolute;
                right: 0;
                top: 0;
                outline: 0;
                opacity: 1;
                color: $black-color;
                transition: $transition;
                line-height: 35px;
                padding: 0;
                margin: 0;
                font-size: 20px;
                width: 35px;
                text-shadow: unset;
                height: 35px;

                &:hover {
                    background-color: red;
                    color: $white-color;
                }
            }
        }
        .shipping-content {
            h3 {
                font-size: 18px;
                margin: {
                    bottom: 15px;
                    top: 30px;
                }
                &:first-child {
                    margin-top: 0;
                }
            }
            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;

                li {
                    margin-bottom: 12px;
                    color: $paragraph-color;
                    position: relative;
                    padding-left: 11px;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 7px;
                        left: 0;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background: $black-color;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
@keyframes wave {
    50% {
        transform: scale(0.9);
    }
}
.related-products-area {
    margin-top: 60px;
    padding-top: 60px;
    padding-bottom: 60px;
    border-top: 1px solid #eeeeee;

    .trending-products-slides-two.owl-theme .owl-nav.disabled + .owl-dots {
        margin-bottom: 0;
    }
}
.col-lg-4 {
    &.col-md-12 {
        &:first-child {
            .product-single-aside {
                border: {
                    right: 1px solid #ebebeb;
                    left: none;
                }
                padding: {
                    right: 15px;
                    left: 0;
                }
            }
        }
    }
}
.product-single-aside {
    height: 100%;
    border-left: 1px solid #ebebeb;;
    padding-left: 15px;

    .aside-trending-products {
        overflow: hidden;
        position: relative;
        text-align: center;
        margin-top: 25px;
    
        .category {
            position: absolute;
            left: 0;
            transition: $transition;
            right: 0;
            bottom: 0;
            background-color: $white-color;
            padding: 20px;
            margin: {
                left: 10px;
                right: 10px;
            }
            h4 {
                font-size: 16px;
                text-transform: uppercase;
                margin-bottom: 7px;
                position: relative;
                z-index: 1;
            }
            span {
                color: $paragraph-color;
                position: relative;
                display: block;
                z-index: 1;
            }
        }
        img {
            transition: $transition;
            width: 100%;
        }
        a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
        }
        &:hover, &:focus {
            img {
                transform: scale(1.3);
            }
            .category {
                bottom: 10px;
            }
        }
        &:first-child {
            margin-top: 0;
        }
    }
    .services-aside {
        .facility-block {
            margin-bottom: 20px;

            h3 {
                font-size: 16px;
                position: relative;
                margin-bottom: 8px;
                padding-left: 25px;

                i {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .products-payments-info {
        text-align: center;
        position: relative;
        margin-top: 45px;
        border: 1px solid #eeeeee;
        padding: 20px;
        padding-top: 45px;

        span {
            display: inline-block;
            color: #858585;
            font: {
                size: 16px;
                weight: 600;
            }
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate3d(-50%,-50%,0);
            background-color: $white-color;
            padding: {
                right: 10px;
                left: 10px;
            }
        }
        .payments-type {
            a {
                display: inline-block;
            }
        }
    }
}

/*================================================
Pagination Area CSS
=================================================*/
.pagination-area {
    margin-top: 30px;
    text-align: center;

    .page-numbers {
        width: 40px;
        height: 40px;
        margin: 0 3px;
        display: inline-block;
        background-color: $white-color;
        line-height: 40px;
        color: $black-color;
        box-shadow: 0 2px 10px 0 #d8dde6;
        font: {
            size: 16px;
            weight: 600;
        }
        &.current, &:hover, &:focus {
            background: $black-color;
            color: $white-color;
            box-shadow: unset;
        }
    }
}

/*================================================
Sidebar Area CSS
=================================================*/
.widget-area {
    .widget {
        margin-top: 30px;

        &:first-child {
            margin-top: 0;
        }
        .widget-title {
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 8px;
            margin-bottom: 20px;
            text-transform: capitalize;
            font: {
                size: 18px;
            }
        }
    }
    .widget_comero_posts_thumb {
        position: relative;
        overflow: hidden;
        
        .item {
            overflow: hidden;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
            .thumb {
                float: left;
                height: 80px;
                overflow: hidden;
                position: relative;
                width: 80px;
                margin-right: 15px;

                .fullimage {
                    width: 80px;
                    height: 80px;
                    display: inline-block;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    }
                    &.bg1 {
                        background-image: url(/images/blog1.jpg);
                    }
                    &.bg2 {
                        background-image: url(/images/blog3.jpg);
                    }
                    &.bg3 {
                        background-image: url(/images/blog4.jpg);
                    }
                }
            }
            .info {
                overflow: hidden;

                time {
                    display: block;
                    color: $paragraph-color;
                    text-transform: uppercase;
                    margin: {
                        top: 5px;
                        bottom: 5px;
                    }
                    font: {
                        size: 12px;
                    }
                }
                .title {
                    margin-bottom: 0;
                    line-height: 1.5;
                    font: {
                        size: 15px;
                    }
                    a {
                        color: $black-color;
                    }
                }
            }
        }
    }
    .widget_recent_entries {
        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                color: $paragraph-color;
                padding-left: 15px;
                line-height: 1.5;
                font: {
                    size: 14px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $black-color;
                    position: absolute;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 6px;
                }
                .post-date {
                    display: block;
                    font-size: 13px;
                    color: $paragraph-color;
                    margin-top: 4px;
                }
                a {
                    color: $black-color;
                }
            }
        }
    }
    .widget_recent_comments {
        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                color: $paragraph-color;
                padding-left: 15px;
                line-height: 1.5;
                font: {
                    size: 14px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $black-color;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 6px;
                    position: absolute;
                }
                span {
                    display: inline-block;
                }
                a {
                    display: inline-block;
                    color: $black-color;
                }
            }
        }
    }
    .widget_archive {
        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                padding-left: 15px;
                font: {
                    size: 14px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $black-color;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 6px;
                    position: absolute;
                }
                a {
                    color: $black-color;
                }
            }
        }
    }
    .widget_categories {
        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                color: $paragraph-color;
                padding-left: 15px;
                font: {
                    size: 14px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $black-color;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 6px;
                    position: absolute;
                }
                a {
                    display: inline-block;
                    color: $black-color;
                }
                .post-count {
                    float: right;
                }
            }
        }
    }
    .widget_meta {
        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                color: $paragraph-color;
                padding-left: 15px;
                font: {
                    size: 14px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $black-color;
                    position: absolute;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 6px;
                }
                a {
                    color: $black-color;
                }
            }
        }
    }
    .tagcloud {
        a {
            display: inline-block;
            font: {
                size: 13px !important;
            }
            color: #858585;
            padding: 7px 10px;
            border: 1px solid #eeeeee;
            margin: {
                top: 8px;
                right: 4px;
            }
            &:hover, &:focus {
                background-color: $black-color;
                color: $white-color;
                border-color: $black-color;
            }
        }
    }
}

/*================================================
Cart Area CSS
=================================================*/
.cart-table {
    table {
        margin-bottom: 0;

        thead {
            tr {
                th {
                    border-bottom-width: 0px;
                    vertical-align: middle;
                    padding: 15px 0;
                    text-transform: uppercase;
                    border: none;
                    font: {
                        weight: 600;
                        size: 15px;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    vertical-align: middle;
                    color: $paragraph-color;
                    padding: {
                        left: 0;
                        right: 0;
                    }
                    border: {
                        color: #eaedff;
                        left: none;
                        right: none;
                    }
                    &.product-thumbnail {
                        a {
                            display: block;

                            img {
                                width: 100px;
                            }
                        }
                    }
                    &.product-name {
                        a {
                            color: $paragraph-color;
                            font-weight: 600;
                            display: inline-block;
                            font-size: 15px;

                            &:hover {
                                color: $black-color;
                            }
                        }
                        ul {
                            padding-left: 0;
                            margin-top: 10px;
                            margin-bottom: 0;
                            list-style-type: none;
                            
                            li {
                                color: #858585;
                                font-size: 12px;
                                margin-bottom: 5px;

                                &:last-child {
                                    margin-bottom: 0;
                                }
                                strong {
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                    &.product-price {
                        span {
                            font-weight: 600;
                        }
                    }
                    &.product-subtotal {
                        .remove {
                            color: red;
                            float: right;
                            position: relative;
                            top: -1px;
                        }
                    }
                    &.product-quantity {
                        .input-counter {
                            max-width: 130px;
                            min-width: 130px;
                            margin-right: 10px;
                            text-align: center;
                            display: inline-block;
                            position: relative;    
        
                            span {
                                position: absolute;
                                top: 0;
                                background-color: transparent;
                                cursor: pointer;
                                color: #d0d0d0;
                                width: 40px;
                                height: 100%;
                                line-height: 50px;
                                transition: $transition;
        
                                &.minus-btn {
                                    left: 0;
                                }
                                &.plus-btn {
                                    right: 0;
                                }
                                &:hover {
                                    color: $black-color;
                                }
                            }
                            input {
                                height: 45px;
                                color: $black-color;
                                outline: 0;
                                display: block;
                                border: none;
                                background-color: #f8f8f8;
                                text-align: center;
                                width: 100%;
                                font: {
                                    size: 17px;
                                    weight: 600;
                                }
                                &::placeholder {
                                    color: $black-color;
                                }
                            }
                        }
                    }
                    &.product-subtotal {
                        overflow: hidden;

                        span {
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}
.cart-buttons {
    margin-top: 30px;
    border-bottom: 1px solid #eaedff;
    padding-bottom: 30px;

    label {
        margin-bottom: 0;

        input {
            position: relative;
            top: 1px;
            margin-right: 4px;
        }
    }
}
.cart-totals {
    background: $white-color;
    padding: 40px;
    max-width: 600px;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    margin: 45px auto 0;

    h3 {
        margin-bottom: 20px;
        font: {
            size: 19px;
        }
    }
    ul {
        padding: 0;
        margin: 0 0 25px;
        list-style-type: none;
        
        li {
            border: 1px solid #eaedff;
            padding: 10px 15px;
            color: $black-color;
            font-weight: 600;
            overflow: hidden;
            
            &:first-child {
                border: {
                    bottom: none;
                }
            }
            &:last-child {
                border: {
                    top: none;
                }
            }
            span {
                float: right;
                color: $paragraph-color;
                font-weight: normal;

                b {
                    font-weight: 600;
                }
            }
        }
    }
}

/*================================================
Checkout CSS
=================================================*/
.user-actions {
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    background: $white-color;
    padding: 18px 25px 15px;
    margin-bottom: 35px;
    border-top: 3px solid $black-color;

    i {
        color: $black-color;
        margin-right: 3px;
    }
    span {
        display: inline-block;
        font-weight: 600;
        color: $black-color;

        a {
            font-weight: 500;
            display: inline-block;
            color: $paragraph-color;

            &:hover, &:focus {
                color: $black-color;
            }
        }
    }
}
.checkout-area {
    .title {
        font: {
            size: 18px;
        }
        margin-bottom: 25px;
    }
}
textarea.form-control {
    height: auto !important;
}
.billing-details {
    .form-group {
        margin-bottom: 20px;
        label {
            display: block;
            color: $black-color;
            margin-bottom: 8px;
            font: {
                size: 14px;
            }
            .required {
                color: red;
            }
        }
        .select-box {
            .nice-select {
                line-height: 45px;
                float: unset;
                font-size: 13px;

                .current {
                    color: $paragraph-color;
                }
                .list {
                    margin: 0;
                    border-radius: 0;
                    border: none;
                    background-color: $white-color;
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

                    .option {
                        line-height: 35px;
                        min-height: 30px;
                    }
                }
            }
        }
        .form-control {
            // background: transparent;
            border: 1px solid #eeeeee;

            &:focus {
                border-color: $black-color;
            }
        }
        textarea {
            padding-top: 15px;
        }
    }
    .form-check {
        margin-bottom: 15px;

        .form-check-label {
            color: $paragraph-color;
        }
        label {
            font: {
                size: 14px;
                weight: 400;
            }
        }
    }
}
.order-details {
    .order-table {
        table {
            margin-bottom: 0;
            text-align: left;
    
            thead {
                tr {
                    th {
                        border-bottom-width: 0;
                        vertical-align: middle;
                        border-color: #eaedff;
                        text-transform: uppercase;
                        padding: 15px .75rem;
                        font: {
                            weight: 600;
                            size: 15px;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        vertical-align: middle;
                        color: $paragraph-color;
                        border-color: #eaedff;
                        font-size: 14px;
    
                        &.product-name {
                            a {
                                color: $paragraph-color;
                                display: inline-block;
                                font: {
                                    size: 15px;
                                }
                                &:hover {
                                    color: $black-color;
                                }
                            }
                        }
                        &.order-subtotal, &.order-shipping , &.total-price {
                            span {
                                color: $black-color;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }
    .payment-method {
        margin-top: 40px;
        border-top: 1px solid #eaedff;
        padding-top: 35px;

        p {
            font: {
                size: 14px;
            }
            [type="radio"] {
                &:checked, &:not(:checked) {
                    display: none;
                }
            }
            [type="radio"] {
                &:checked, &:not(:checked) {
                    + label {
                        padding-left: 27px;
                        cursor: pointer;
                        display: block;
                        font: {
                            weight: 600;
                        }
                        color: $black-color;
                        position: relative;
                        margin-bottom: 8px;

                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 3px;
                            width: 18px;
                            height: 18px;
                            border: 1px solid #dddddd;
                            border-radius: 50%;
                            background: $white-color;
                        }
                        &::after {
                            content: '';
                            width: 12px;
                            height: 12px;
                            background: $black-color;
                            position: absolute;
                            top: 6px;
                            left: 3px;
                            border-radius: 50%;
                            transition: $transition;
                        }
                    }
                }
            }
            [type="radio"] {
                &:not(:checked) {
                    + label {
                        &::after {
                            opacity: 0;
                            visibility: hidden;
                            transform: scale(0);
                        }
                    }
                }
            }
            [type="radio"] {
                &:checked {
                    + label {
                        &::after {
                            opacity: 1;
                            visibility: visible;
                            transform: scale(1);
                        }
                    }
                }
            }
        }
    }
    .order-btn {
        margin-top: 15px;

        span {
            display: block;

            .btn {
                width: 100%;
            }
            .btn-primary {
                &.btn-disabled {
                    background-color: #717070;
                    border-color: #717070;
                    cursor: not-allowed;

                    &:hover {
                        background-color: transparent;
                        border-color: $black-color;
                    }
                }
            }
        }
    }
}

/*================================================
Compare Area CSS
=================================================*/
.products-compare-table {
    .remove-btn {
        margin-bottom: 15px;

        a {
            color: red;
            display: inline-block;
        }
    }
}

/*================================================
Login Area CSS
=================================================*/
.login-area {
    .section-title {
        margin-bottom: 25px;
    }
}
.login-form {
    padding-right: 15px;

    .form-group {
        margin-bottom: 20px;

        label {
            display: block;
            text-transform: uppercase;
            margin-bottom: 7px;
        }
        .form-control {
            border: 1px solid #f5f5f5;

            &:focus {
                border-color: $black-color;
            }
        }
    }
    .btn {
        display: block;
        width: 100%;
        padding: 14px 25px;
    }
    .forgot-password {
        display: inline-block;
        margin-top: 15px;
        color: $paragraph-color;
        position: relative;
        font-size: 15px;
        
        &::before {
            width: 100%;
            height: 1.5px;
            background: $paragraph-color;
            bottom: 0;
            left: 0;
            position: absolute;
            transition: $transition;
            content: '';
        }
        &:hover {
            color: $black-color;
            
            &::before {
                width: 0;
            }
        }
    }
}
.new-customer-content {
    padding-left: 15px;

    span {
        display: block;
        font: {
            size: 17px;
            weight: 600;
        }
        margin-bottom: 8px;
    }
    p {
        margin-bottom: 20px;
    }
}

/*================================================
Signup Area CSS
=================================================*/
.signup-area {
    .section-title {
        margin-bottom: 25px;
    }
}
.signup-content {
    max-width: 520px;
    margin: 0 auto;
}
.signup-form {
    .form-group {
        margin-bottom: 20px;

        label {
            display: block;
            text-transform: uppercase;
            margin-bottom: 7px;
        }
        .form-control {
            border: 1px solid #f5f5f5;

            &:focus {
                border-color: $black-color;
            }
        }
    }
    .btn {
        display: block;
        width: 100%;
        padding: 14px 25px;
    }
    .return-store {
        display: inline-block;
        margin-top: 15px;
        color: $paragraph-color;
        position: relative;
        font-size: 15px;
        
        &::before {
            width: 100%;
            height: 1.5px;
            background: $paragraph-color;
            bottom: 0;
            left: 0;
            position: absolute;
            transition: $transition;
            content: '';
        }
        &:hover {
            color: $black-color;
            
            &::before {
                width: 0;
            }
        }
    }
}

/*================================================
Customer Service Area CSS
=================================================*/
.customer-service-content {
    h3 {
        text-transform: uppercase;
        position: relative;
        padding-left: 23px;
        margin: {
            bottom: 20px;
        }
        font: {
            size: 16px;
            weight: normal;
        }
        i {
            position: absolute;
            top: 1px;
            left: 0;
        }
        &:not(:first-child) {
            margin-top: 30px;
        }
    }
    ul {
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 0;

        li {
            color: $paragraph-color;
            margin-bottom: 10px;
            position: relative;
            padding-left: 11px;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 7.5px;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background: $black-color;
            }
            &:last-child {
                margin-bottom: 0;
            }
            a {
                display: inline-block;
                color: $paragraph-color;

                &:hover {
                    color: $black-color;
                }
            }
        }
    }
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
    background-color: $white-color;
    padding-top: 40px;
    position: relative;
    z-index: 1;
    border-top: 1px solid #f3f3f3;
}
.single-footer-widget {
    .logo {
        margin-bottom: 15px;

        a {
            display: inline-block;
        }
    }
    h3 {
        font-size: 17px;
        text-transform: uppercase;
        position: relative;
        margin-bottom: 14px;
        padding-bottom: 5px;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            background: #ebebeb;
            width: 45px;
            height: 2px;
        }
    }
    p {
        font-size: 13px;
    }
    .quick-links {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            color: $paragraph-color;
            margin-bottom: 8px;
            font-size: 13px;

            a {
                color: $paragraph-color;
                display: inline-block;

                &:hover, &:focus {
                    color: $black-color;
                    padding-left: 5px;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .information-links {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            color: $paragraph-color;
            margin-bottom: 8px;
            font-size: 13px;

            a {
                color: $paragraph-color;
                display: inline-block;

                &:hover, &:focus {
                    color: $black-color;
                    padding-left: 5px;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .footer-contact-info {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            color: $paragraph-color;
            margin-bottom: 10px;
            font-size: 13px;
            position: relative;
            padding-left: 20px;

            i {
                color: $black-color;
                position: absolute;
                left: 0;
                top: 4px;
            }
            a {
                color: $paragraph-color;
                display: inline-block;

                &:hover, &:focus {
                    color: $black-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
.copyright-area {
    margin-top: 40px;
    background-color: #f5f5f5;
    padding: {
        top: 15px;
        bottom: 15px;
    }
    .payment-card {
        padding: 0;
        margin: 0;
        list-style-type: none;
        text-align: right;

        li {
            display: inline-block;
        }
    }
    a {
        display: inline-block;
        color: #1b28ce;
    }
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
	position: fixed;
	cursor: pointer;
	bottom: 25px;
	right: 15px;
	color: $white-color;
	background-color: $black-color;
	z-index: 4;
	// display: none;
	width: 40px;
	text-align: center;
	height: 40px;
    line-height: 40px;
    
    i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        transition: $transition;

        &:last-child {
            opacity: 0;
            visibility: hidden;
            top: 60%;
        }
    }
    &:hover, &:focus {
        i {
            &:first-child {
                opacity: 0;
                top: 0;
                visibility: hidden;
            }
            &:last-child {
                opacity: 1;
                visibility: visible;
                top: 50%;
            }
        }
    }
}

.opacityZero {
    opacity: 0;
}

.opacityOne {
    opacity: 1 !important;
}

.block {
    display: block;
}

.none{
    display: none;
}
.thank-you-area {
    text-align: center;
    padding: {
        top: 150px;
        bottom: 150px;
    }
    h1 {
        margin-bottom: 15px;
    }
}
.main-banner-three.item-bg4 {
    position: relative;
    &::before {
        content: '';
        background-color: #000000;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        opacity: .20; 
    }
    .main-banner-content {
        position: relative;
    }
}
.accordion__button:focus {
    outline: 0;
}

/* Home five style */
.header-area {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    z-index: 999;
}
.navbar-area {
    .container-fluid {
        padding: {
            left: 35px;
            right: 35px;
        };
    }
    &.bg-black {
        .comero-nav {
            background-color: rgba(0, 0, 0, .36);

            .navbar {
                .navbar-nav {
                    .nav-item {
                        a {
                            color: $white-color;
                            
                            &:hover, &:focus, &.active {
                                color: $white-color;
                            }
                        }
                        &:hover, &:focus, &.active {
                            a {
                                color: $white-color;
                            }
                        }
                        .dropdown-menu {
                            li {
                                a {
                                    color: $paragraph-color;
                                    
                                    &:hover, &:focus, &.active {
                                        color: $black-color;
                                    }
                                }
                                .dropdown-menu {
                                    li {
                                        a {
                                            color: $paragraph-color;
        
                                            &:hover, &:focus, &.active {
                                                color: $black-color;
                                            }
                                        }
                                    }
                                    li {
                                        a {
                                            color: $paragraph-color;
                                            
                                            &:hover, &:focus, &.active {
                                                color: $black-color;
                                            }
                                        }
                                        .dropdown-menu {
                                            li {
                                                a {
                                                    color: $black-color;
                
                                                    &:hover, &:focus, &.active {
                                                        color: $black-color;
                                                    }
                                                }
                                            }
                                        }
                                        &.active {
                                            a {
                                                color: $black-color;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    a {
                                        color: $black-color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .others-option {
                .option-item {
                    a {
                        color: $white-color;
                        
                        &::before {
                            background: $white-color;
                        }
                        &:hover, &:focus {
                            color: $white-color;
                        }
                    }
                    &::before {
                        background: $white-color;
                    }
                    .search-btn {
                        color: $white-color;
                    }
                    .close-btn {
                        color: $white-color;
                    }
                }
            }

            &.is-sticky {
                background-color: rgba(0, 0, 0, 1) !important;
            }
        }
        
    }
}

/* Home slider */
.home-slides-two {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;
            [class*=owl-] {
                position: absolute;
                left: 30px;
                top: 58%;
                transform: translateY(-58%);
                margin: 0;
                outline: 0;
                width: 45px;
                height: 45px;
                line-height: 38px;
                transition: $transition;
                color: rgba(255, 255, 255, .88);
                background-color: transparent;
                border: 1px solid rgba(255, 255, 255, .88);
                font-size: 20px;
                border-radius: 50%;

                &:hover {
                    background-color: $white-color;
                    color: $black-color;
                }
                &.owl-next {
                    left: auto;
                    right: 30px;
                }
            }
        }
    }
}
.banner-section {
    height: 850px;
    position: relative;
    z-index: 1;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
}
.item-bg5 {
    background-image: url(/images/main-banner5.jpg);
}
.item-bg6 {
    background-image: url(/images/main-banner6.jpg);
}
.banner-content {
    margin-top: 80px;
    max-width: 520px;
    position: relative;
    z-index: 1;

    .sub-title {
        font-size: $font-size;
        color: $white-color;
        display: block;
        margin: {
            bottom: 11px;
        }
    }
    h1 {
        font: {
            size: 34px;
            weight: 700;
        }
        color: $white-color;
        text-transform: uppercase;
        margin: {
            bottom: 15px;
        }
    }
    p {
        line-height: initial;
        font-size: 18px;
        color: $white-color;
        margin-bottom: 30px;
    }
    .btn-primary {
        &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {
            color: $black-color;
            border-color: $black-color;
            background-color: $white-color;
        }
        &:hover, &:focus {
            color: $black-color;
            border-color: $black-color;
            background-color: $white-color;
        }
    }
    &::before {
        content: '';
        position: absolute;
        top: -50px;
        width: 100%;
        max-width: 380px;
        text-align: center;
        height: 285px;
        left: -50px;
        z-index: -1;
        border: 10px solid rgba(255, 255, 255, .20);
    }
}

// Category boxes area
.category-boxes-area {
    padding-bottom: 30px;
}
.single-category-boxes {
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;

    img {
        transition: $transition;
        width: 100%;
    }
    .link-btn {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }
    .shop-now-btn {
        display: inline-block;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 3;
        background-color: $white-color;
        text-transform: uppercase;
        padding: 12px 30px;
        color: $black-color;
        transition: $transition;
        font: {
            size: 14px;
            weight: 500;
        };
        &:hover {
            background-color: $black-color;
            color: $white-color;
        }
    }
    h3 {
        margin-bottom: 0;
        text-transform: uppercase;
        position: absolute;
        left: 25px;
        top: 25px;
        font: {
            size: 18px;
            weight: 700;
        };
    }
    &:hover {
        img {
            transform: scale(1.1) rotate(4deg);
        }
        .shop-now-btn {
            background-color: $black-color;
            color: $white-color;
        }
    }
}

/* All products slides two */
.all-products-slides-two {
    &.owl-theme {
        .owl-nav {
            &.disabled + .owl-dots {
                margin: {
                    top: 15px;
                    bottom: 30px;
                };
            }
        }
    }
}
/* End home five style */

/* Start home Six, Seven, Eight, Nine, Ten & Eleven Style */

/*================================================
Template Boxed Area CSS
=================================================*/
.boxed-layout-wrapper {
    background: #f7f8fa;

    .boxed-layout-content {
        background: $white-color;
        max-width: 1200px;
        width: 100%;
        margin: {
            left: auto;
            right: auto;
        };
    }
    
    .navbar-area {
        &.is-sticky {
            right: 0;
            max-width: 1200px;
            margin: {
                left: auto;
                right: auto;
            };
        }
    }
    .comero-nav {
        .navbar {
            .navbar-nav {
                .nav-item {
                    .dropdown-menu {
                        left: auto;
                        right: 0;

                        li {
                            .dropdown-menu {
                                left: auto;
                                right: 240px;

                                li {
                                    .dropdown-menu {
                                        right: auto;
                                        left: 240px;
                                    }
                                }
                            }
                        }
                    }
                    &.megamenu {
                        .dropdown-menu {
                            max-width: 1200px;
                            right: 0;
                            left: 0;
                            margin: {
                                left: auto;
                                right: auto;
                            };
                        }
                    }
                }
            }
        }
    }
}

/*================================================
Top Header Area CSS
=================================================*/
.top-header-logo {
    text-align: center;
}
.top-header-others-option {
    text-align: right;
    
    .option-item {
        display: inline-block;
        position: relative;
        margin-right: 17px;
        line-height: initial;

        a {
            color: $black-color;
            text-transform: uppercase;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 1px;
                background: $black-color;
                transition: $transition;
            }
            &:hover, &:focus {
                color: $black-color;

                &::before {
                    width: 100%;
                }
            }
        }
        &::before {
            content: '';
            position: absolute;
            right: -12px;
            top: 0.5px;
            width: 1px;
            height: 12px;
            background: #888888;
        }
        &:last-child {
            margin-right: 0;

            &::before {
                display: none;
            }
        }
        .search-btn {
            cursor: pointer;
        }
        .close-btn {
            cursor: pointer;
            display: none;

            &.active {
                display: block;
            }
        }
    }
    .search-overlay {
        &.search-popup {
            margin-top: 20px;
        }
    }
}
.top-header-style-two {
    padding: {
        top: 15px;
        bottom: 15px;
    };
    .top-header-right-nav {
        text-align: left;
    }
}
.top-header-social {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;

    li {
        display: inline-block;
        margin-right: 15px;
        position: relative;

        a {
            text-transform: uppercase;
            font-size: 13px;
            color: $black-color;

            &:hover {
                color: $paragraph-color;
            }
        }
        &::before {
            content: '';
            position: absolute;
            right: -10px;
            top: 3.2px;
            height: 10px;
            width: 1px;
            background: $black-color;
        }
        &:last-child {
            margin-right: 0;

            &::before {
                display: none;
            }
        }
    }
}
.top-header-discount-info {
    text-align: center;

    p {
        margin-bottom: 0;

        strong {
            color: $black-color;
        }
        a {
            display: inline-block;
            color: $black-color;
            font-weight: 700;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 3px;
                width: 100%;
                height: 1px;
                transition: $transition;
                background-color: $black-color;
            }
            &:hover {
                &::before {
                    width: 0;
                }
            }
        }
    }
}

/*================================================
Navbar Area CSS
=================================================*/
.navbar-area {
    &.navbar-style-two {
        .navbar-nav {
            margin: {
                left: auto;
                right: auto;
            };
        }
    }
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
    &.item-bg7 {
        background-image: url(/images/main-banner-image/banner-new-bg1.jpg);
    }
    &.item-bg8 {
        background-image: url(/images/main-banner-image/banner-new-bg2.jpg);
    }
    &.item-bg9 {
        background-image: url(/images/main-banner-image/banner-new-bg3.jpg);
    }
    &.item-bg10 {
        background-image: url(/images/main-banner-image/banner-new-bg4.jpg);
    }
    &.item-bg11 {
        background-image: url(/images/main-banner-image/banner-new-bg5.jpg);
    }
    &.item-bg12 {
        background-image: url(/images/main-banner-image/banner-new-bg6.jpg);
    }
    &.item-bg13 {
        background-image: url(/images/main-banner-image/banner-new-bg7.jpg);
    }
    &.item-bg14 {
        background-image: url(/images/main-banner-image/banner-new-bg8.jpg);
    }
    &.item-bg15 {
        background-image: url(/images/main-banner-image/banner-new-bg9.jpg);
    }
    &.item-bg16 {
        background-image: url(/images/main-banner-image/banner-new-bg10.jpg);
    }
    &.item-bg17 {
        background-image: url(/images/main-banner-image/banner-new-bg11.jpg);
    }
    &.item-bg18 {
        background-image: url(/images/main-banner-image/banner-new-bg12.jpg);
    }
    &.item-bg19 {
        background-image: url(/images/main-banner-image/banner-new-bg13.jpg);
    }
    &.item-bg20 {
        background-image: url(/images/main-banner-image/banner-new-bg14.jpg);
    }
    &.item-bg21 {
        background-image: url(/images/main-banner-image/banner-new-bg15.jpg);
    }
    &.item-bg22 {
        background-image: url(/images/main-banner-image/banner-new-bg16.jpg);
    }
}
.main-banner-content {
    &.white-color {
        .btn-light {
            color: $white-color;
            border-color: $white-color;

            &:hover, &:focus {
                color: $black-color;
                background-color: $white-color;
                border-color: $white-color;
            }
        }
    }
    &.text-center {
        margin: {
            left: auto;
            right: auto;
        };
    }
}
.home-slides-three {
    &.owl-theme {
        .owl-nav {
            &.disabled + .owl-dots {
                margin-top: 0;
            }
        }
        .owl-dots {
            position: absolute;
            right: 50px;
            top: 50%;
            transform: translateY(-50%);

            .owl-dot {
                display: block;

                span {
                    width: 15px !important;
                    height: 15px !important;
                    background-color: transparent !important;
                    border: 1px solid transparent;
                    position: relative;
                    margin: {
                        left: 0 !important;
                        right: 0 !important;
                        bottom: 5px !important;
                        top: 5px !important;  
                    };
                    &::before {
                        content: '';
                        position: absolute;
                        border-radius: 50%;
                        transition: $transition;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        margin: 2px;
                        background-color: $white-color;
                    }
                }
                &:hover, &.active {
                    span {
                        border-color: $white-color;
                    }
                }
            }
        }
        &.black-color-text {
            .owl-dots {
                .owl-dot {    
                    span {
                        &::before {
                            background-color: $black-color;
                        }
                    }
                    &:hover, &.active {
                        span {
                            border-color: $black-color;
                        }
                    }
                }
            }
        }
        &.bottom-carousel-dots {
            .owl-dots {
                right: 0;
                left: 0;
                top: auto;
                transform: unset;
                bottom: 25px;
    
                .owl-dot {
                    display: inline-block;
    
                    span {
                        margin: {
                            left: 3px !important;
                            right: 3px !important;
                            bottom: 0 !important;
                            top: 0 !important;
                        };
                    }
                }
            }
        }
    }
}

// Covid-19 Banner
.covid-19-banner {
    height: 700px;
    position: relative;
    &.covid-19-item-bg1 {
        background-image: url(/images/main-banner-image/banner-new-bg17.jpg);
    }
    &.covid-19-item-bg2 {
        background-image: url(/images/main-banner-image/banner-new-bg18.jpg);
    }

    .main-banner-content {
        p {
            line-height: 1.6;
            margin-bottom: 30px;
        }
    }
}

.instagram-title {
    text-align: center;
    margin-bottom: 30px;

    a {
        display: inline-block;
        position: relative;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding-left: 32px;
        font-size: 15px;
        font-weight: 300;
        color: $black-color;
    }
}

.offer-area-two {
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding-top: 100px;
    padding-bottom: 100px;

    &.offer-bg-image {
        background-image: url(/images/offer-bg3.jpg);
    }

    .offer-content-box {
        max-width: 720px;
        margin-right: auto;
        text-align: center;
        position: relative;
        z-index: 1;

        span {
            display: block;
            color: #fff;
            font-weight: 300;
            font-size: 30px;
        }
        h2 {
            margin: 10px 0;
            color: #fff;
            font-size: 65px;
            font-weight: 700;
        }
        p {
            color: #fff;
            font-size: 20px;
            font-weight: 300;
        }

        .btn-primary {
            background-color: #dc3545;
            border-color: #dc3545;

            &:hover {
                background-color: #be3340;
                border-color: #be3340;
                color: #fff;
            }
        }
    }
}

.single-product-two {
    .product-content {
        margin: 0;
        border-left: 1px solid #f7f7f7;
        border-right: 1px solid #f7f7f7;
        padding-top: 25px;
        h3 {
            font-size: 18px;
            font-weight: 600;
        }
        .rating {
            margin-bottom: 25px;
        }
    }
}

// Grocery Banner
.grocery-banner {
    height: 700px;
    background-position: center center;
    background-size: cover;
    background-image: url(/images/grocery-banner-bg.jpg);

    .main-banner-content {
        max-width: 720px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;

        h1 {
            font-size: 50px;
            font-style: italic;
            text-transform: capitalize;
            margin-top: 0;
            margin-bottom: 18px;
        }
        p {
            color: #000;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 20px;
        }
        h2 {
            color: #e7160e;
            font-size: 50px;
            font-weight: 700;
            margin-bottom: 20px;
        }
        .btn-primary {
            background-color: #dc3545;
            border-color: #dc3545;
            &:hover {
                background-color: #be3340;
                border-color: #be3340;
                color: #fff;
            }
        }
    }
}

.electronics-banner {
    background-image: url(/images/electronics-banner-bg.jpg);
}

.furniture-banner {
    background-image: url(/images/furniture-banner-bg.jpg);

    .main-banner-content {
        max-width: 100%;
        text-align: left;
    }
}

/* Offer Categories CSS */
.categories-banner-area {
    .container-fluid {
        padding-left: 30px;
        padding-right: 30px;
    }
}
.offer-categories-box {
    margin-bottom: 30px;
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    
    img {
        transition: $transition;
        border-radius: 5px;
        width: 100%;
    }
    .content {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 25px 25px 0;
        text-align: right;

        span {
            display: block;
            margin-bottom: 8px;
            transition: $transition;
            color: #dc3545;
            font-weight: 600;
            transform: translateY(12px);
        }
        h3 {
            margin-bottom: 0;
            transition: $transition;
            transform: translateY(12px);
            font: {
                size: 30px;
                weight: 700;
            };
        }
        .btn {
            background-color: transparent;
            color: $black-color;
            border-color: $black-color;
            opacity: 0;
            visibility: hidden;
            transform: translateY(10px);
        }
        &.text-white {
            span {
                color: $white-color;
            }
            h3 {
                color: $white-color;
            }
        }
    }
    .link-btn {
        display: block;
        width: 100%;
        height: 100%;
        z-index: 2;
        left: 0;
        top: 0;
        position: absolute;
    }
    &:hover {
        img {
            transform: scale(1.07);
        }
        .content {
            span {
                transform: translateY(-30px);
            }
            h3 {
                transform: translateY(-30px);
            }
            .btn {
                background-color: #dc3545;
                color: $white-color;
                border-color: #dc3545;
                opacity: 1;
                visibility: visible;
                transform: translateY(-15px);
            }
        }
    }
}
 
.offer-categories-box {
    &.hover-active {
        .content {
            span {
                transform: translateY(-30px);
            }
            h3 {
                transform: translateY(-30px);
            }
            .btn {
                background-color: #dc3545;
                color: $white-color;
                border-color: #dc3545;
                opacity: 1;
                visibility: visible;
                transform: translateY(-15px);
            }
        }
    }
    &.overly {
        position: relative;
        transition: $transition;
        &::before {
            position: absolute;
            content: '';
            background-color: rgba(0, 0, 0, 0.50);
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transition: $transition;
        }
    }
}

.left-categories {
    transition: $transition;
    .content {
        right: auto;
        text-align: left;
        transition: $transition;
    }
    &:hover {
        transition: $transition;
        .content {
            bottom: 15px;
        }
    }
}
.bg-fcfbfb {
    background-color: #fcfbfb;
}
 
/*================================================
All Products Area CSS
=================================================*/
.product-box {
    margin-bottom: 30px;
    position: relative;
    text-align: center;

    .product-image {
        overflow: hidden;
        position: relative;

        img {
            transition: $transition;
            width: 100%;
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            position: absolute;
            top: 55%;
            transform: translateY(-55%);
            right: 0;
            left: 0;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
            margin-top: 25px;

            li {
                margin-bottom: 8px;
                display: inline-block;
                margin: {
                    left: 4px;
                    right: 4px;
                };
                a {
                    width: 40px;
                    height: 40px;
                    line-height: 42px;
                    background-color: $white-color;
                    color: $black-color;
                    border-radius: 50%;
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

                    &:hover, &:focus {
                        color: $white-color;
                        background-color: $black-color;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .sale-tag {
            position: absolute;
            top: 10px;
            left: 10px;
            background: #ff2d2d;
            color: $white-color;
            width: 50px;
            height: 50px;
            line-height: 50px;
            border-radius: 50%;
            z-index: 2;
        }
        #timer {
            position: absolute;
            left: 0;
            text-align: center;
            right: 0;
            margin: 0 auto;
            bottom: 15px;

            div {
                display: inline-block;
                background-color: rgba(255, 255, 255, 0.9);
                padding: 8px 15px;
                color: $black-color;
                border-radius: 5px;
                font: {
                    size: 18px;
                    weight: 600;
                }
                span {
                    display: block;
                    font: {
                        size: 13px;
                        weight: normal;
                    }
                    color: $paragraph-color;
                }
            }
        }
    }
    .product-content {
        margin-top: 20px;
        
        h3 {
            font-size: 18px;
            margin-bottom: 0;

            a {
                color: $black-color;

                &:hover, &:focus {
                    color: $black-color;
                }
            }
        }
        .product-price {
            margin: {
                top: 8px;
                bottom: 10px;
            }
            span {
                font-size: 17px;
                display: inline-block;
            }
            .old-price {
                color: #858585;
                font-size: 15px;
                text-decoration: line-through;
            }
        }
        .rating {
            margin-bottom: 15px;
            color: #eac11d;
            i {
                margin: 0 2px;
            }
        }
        .btn {
            display: block;
            margin: {
                left: 35px;
                right: 35px;
            };
        }
    }
    &:hover, &:focus {
        .product-image {
            img {
                transform: scale(1.2) rotate(4deg);
            }
            ul {
                margin-top: 0;
                opacity: 1;
                visibility: visible;
            }
        }
        .product-content {
            .btn-light {
                background-color: $black-color;
                color: $white-color;
            }
        }
    }
}
.single-product-item {
    margin-bottom: 30px;
    position: relative;

    .product-image {
        overflow: hidden;
        position: relative;

        img {
            transition: $transition;
            width: 100%;
        }
        .sale-tag {
            text-align: center;
            position: absolute;
            top: 10px;
            left: 10px;
            background: #ff2d2d;
            color: $white-color;
            width: 50px;
            height: 50px;
            line-height: 50px;
            border-radius: 50%;
            z-index: 2;
        }
        #timer {
            position: absolute;
            left: 0;
            text-align: center;
            right: 0;
            margin: 0 auto;
            bottom: 15px;

            div {
                display: inline-block;
                background-color: rgba(255, 255, 255, 0.9);
                padding: 8px 15px;
                color: $black-color;
                border-radius: 5px;
                font: {
                    size: 18px;
                    weight: 600;
                }
                span {
                    display: block;
                    font: {
                        size: 13px;
                        weight: normal;
                    }
                    color: $paragraph-color;
                }
            }
        }
    }
    .product-content {
        margin-top: 20px;
        
        h3 {
            margin-bottom: 0;
            font: {
                weight: 600;
                size: 18px;
            };
            a {
                color: $black-color;

                &:hover, &:focus {
                    color: $black-color;
                }
            }
        }
        .product-price {
            margin: {
                top: 8px;
                bottom: 12px;
            }
            span {
                font-size: 17px;
                display: inline-block;
            }
            .old-price {
                color: #858585;
                font-size: 15px;
                text-decoration: line-through;
            }
        }
        .btn {
            padding: 10px 20px;
        }
        ul {
            text-align: right;
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                display: inline-block;
                margin-left: 5px;

                a {
                    width: 35px;
                    height: 35px;
                    line-height: 38px;
                    text-align: center;
                    background-color: $white-color;
                    color: $black-color;
                    border-radius: 50%;
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

                    &:hover, &:focus {
                        color: $white-color;
                        background-color: $black-color;
                    }
                }
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
    &:hover, &:focus {
        .product-image {
            img {
                transform: scale(1.1) rotate(3deg);
            }
        }
        .product-content {
            .btn-light {
                background-color: $black-color;
                color: $white-color;
            }
        }
    }
}
.popular-products-area {
    padding-bottom: 30px;
}
.products-category-tab-style-2 {
    position: relative;
    padding: {
        left: 20px;
        right: 20px;
    };
    margin: {
        left: -20px;
        right: -20px;
    };
    .title {
        position: absolute;
        left: 20px;
        top: 0;

        h2 {
            margin-bottom: 0;
            display: inline-block;
            position: relative;
            padding-left: 20px;
            font: {
                size: 18px;
                weight: 600;
            }
            .dot {
                position: absolute;
                top: 3px;
                left: 0;
                width: 12px;
                height: 12px;
                border: 1px solid $black-color;
    
                &::before {
                    position: absolute;
                    top: 0;
                    content: '';
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: $black-color;
                    margin: 1px;
                }
            }
        }
    }
    .tabs {
        text-align: right;
        margin-bottom: 30px;
        padding-left: 0;
        list-style-type: none;
        border-bottom: 1px solid #e4e4e4;
        padding-bottom: 5px;

        li {
            display: inline-block;
            margin-right: 15px;

            span, a {
                color: #858585;
                position: relative;
                display: inline-block;
                cursor: pointer;

                font: {
                    weight: 400;
                    size: 18px;
                };
                &:hover {
                    color: $black-color;

                    &::before {
                        width: 100%;
                    }
                }
                &::before {
                    height: 3px;
                    width: 0;
                    background-color: $black-color;
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -7px;
                    transition: $transition;
                }
            }
            &.current {
                span, a {
                    color: $black-color;

                    &::before {
                        width: 100%;
                    }
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
.product-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;

            [class*=owl-] {
                position: absolute;
                left: 0;
                top: 40%;
                transform: translateY(-40%);
                width: 40px;
                height: 40px;
                border-radius: 50%;
                outline: 0;
                margin: 0;
                background-color: $white-color;
                color: #999999;
                font-size: 17px;
                box-shadow: 0px 15px 20px -12px #000000;
                line-height: 35px;
                transition: $transition;
                opacity: 0;
                visibility: hidden;

                &:hover {
                    color: $white-color;
                    background-color: $black-color;
                }
                &.owl-next {
                    left: auto;
                    right: 0;
                }
            }
        }
        &:hover {
            .owl-nav {    
                [class*=owl-] {
                    left: -20px;
                    opacity: 1;
                    visibility: visible;

                    &.owl-next {
                        left: auto;
                        right: -20px;
                    }
                }
            }
        }
    }
}
.product-slides-count {
    text-align: center;
    margin: {
        top: 15px;
        bottom: 30px;
    };
    .slider-counter {
        display: inline-block;
        background-color: #f1f1f1;
        padding: 2px 14px 1px;
        font: {
            size: 18px;
            weight: 500;
            style: italic;
        };
    }
}

/*================================================
Category Products Area CSS
=================================================*/
.category-boxes-area {
    .section-title {
        background-color: transparent;
        padding: 0;

        h2 {
            padding-left: 0;
            font-weight: 700;

            .dot {
                display: none;
            }
        }
    }
}
.category-boxes {
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 30px;
    position: relative;

    img {
        border-radius: 5px;
        width: 100%;
        transition: $transition;
    }
    .content {
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);

        p {
            margin-bottom: 10px;
            line-height: initial;
            color: $white-color;
        }
        h3 {
            color: $white-color;
            margin-bottom: 0;
            font: {
                size: 22px;
                weight: 700;
            };
        }
        span {
            display: block;
            color: $white-color;
            margin-top: 10px;
        }
        .shop-now-btn {
            margin-top: 15px;
            display: inline-block;
            border: 1px solid $white-color;
            padding: 11px 25px;
            line-height: initial;
            border-radius: 0;
            transition: $transition;
            text-transform: uppercase;
            color: $white-color;
            font: {
                weight: 400;
                size: 14px;
            };
            &:hover {
                background-color: $black-color;
                color: $white-color;
                border-color: $black-color;
            }
        }
        &.black-text {
            p {
                color: $black-color;
            }
            h3 {
                color: $black-color;
            }
            span {
                color: $black-color;
            }
            .shop-now-btn {
                border-color: $black-color;
                color: $black-color;
                
                &:hover {
                    background-color: $black-color;
                    color: $white-color;
                    border-color: $black-color;
                }
            }
        }
    }
    &:hover {
        img {
            transform: scale(1.1);
        }
        .content {
            .shop-now-btn {
                background-color: $black-color;
                color: $white-color;
                border-color: $black-color;
            }
            &.black-text {
                .shop-now-btn {
                    background-color: $black-color;
                    color: $white-color;
                    border-color: $black-color;
                }
            }
        }
    }
}
.category-box {
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;

    img {
        transition: $transition;
        width: 100%;
    }
    .category-content {
        position: absolute;
        top: 50px;
        left: 30px;

        h3 {
            margin-bottom: 15px;
            font: {
                size: 20px;
                weight: 700;
            };
        }
    }
    &:hover {
        img {
            transform: scale(1.2) rotate(4deg);
        }
        .category-content {
            .btn-light {
                background-color: $black-color;
                border-color: $black-color;
                color: $white-color;
            }
        }
    }
}

/*================================================
Products Collections Area CSS
=================================================*/
.products-collections-area {
    .woocommerce-pagination {
        margin-bottom: 30px;
    }
}
.products-collections-listing {
    &.products-row-view {
        .product-box {
            display: flex;
            flex-wrap: wrap;
            align-items: center!important;
            background-color: #fdfdfd;

            .product-image {
                flex: 0 0 50%;
                max-width: 50%;
            }
            .product-content {
                flex: 0 0 50%;
                max-width: 50%;
                margin-top: 0;
                padding: 20px;
            }
        }
    }
}

/*================================================
News Area CSS
=================================================*/
.single-blog-post-box {
    margin-bottom: 30px;

    .post-content {
        margin-top: 25px;

        .post-meta {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                display: inline-block;
                margin-right: 25px;
                color: $paragraph-color;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    right: -20px;
                    top: 10px;
                    width: 10px;
                    height: 1px;
                    background-color: $black-color;
                }
                a {
                    color: $paragraph-color;
                    display: inline-block;

                    &:hover {
                        color: $black-color;
                    }
                }
                &:last-child {
                    margin-right: 0;

                    &::before {
                        display: none;
                    }
                }
            }
        }
        h3 {
            margin: {
                top: 10px;
                bottom: 0;
            };
            line-height: 27px;
            font: {
                size: 18px;
                weight: 600;
            };
            a {
                color: $black-color;

                &:hover, &:focus {
                    color: $black-color;
                }
            }
        }
    }
}

/*================================================
Pagination CSS
=================================================*/
.woocommerce-pagination {
    text-align: center;
    margin-top: 10px;

    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            display: inline-block;
            margin: {
                left: 1px;
                right: 1px;
            };
            .page-numbers {
                display: inline-block;
                width: 35px;
                line-height: 35px;
                height: 35px;
                color: $black-color;
                transition: $transition;
                border: {
                    bottom: 1px solid $paragraph-color;
                };
                font: {
                    size: $font-size;
                    weight: 600;
                };
                &.dots {
                    border-color: $paragraph-color !important;
                    background-color: transparent !important;
                    color: $paragraph-color !important;
                }
                &:hover, &.current {
                    background-color: $black-color;
                    color: $white-color;
                    border-color: $black-color;
                }
            }
        }
    }
}
/* End home Six, Seven, Eight, Nine, Ten & Eleven Style */